import React, {useEffect, useState} from 'react';
import {ConfigProvider, Layout} from 'antd';
import Routes from './routes/AdminRoute';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import 'antd/dist/antd.less'
import './style/index.less';
import moment from 'moment';
import 'moment/locale/zh-cn';
import AdminMenu from "./components/admin/components/AdminMenu";
import AdminHeader from "./components/admin/components/AdminHeader";

// 推荐在入口文件全局设置 locale
moment.locale('zh-cn');
const {Content, Header, Footer, Sider} = Layout;


/**
 * 后台管理页面
 * @param user
 * @returns {JSX.Element}
 * @constructor
 */

const Admin = ({user}) => {
    const [breadCrumbData, setBreadCrumbData] = useState([]);

    useEffect(() => {
    }, [])


    const getBreadCrumbData = (data) => {
        setBreadCrumbData(data)
    }

    return (
        <ConfigProvider locale={zhCN} form={{
            validateMessages: {
                required: "'${label}'不能为空",
                default: "'${label}' 输入有误,请检查",
                enum: "'${label}' 必段属于 [${enum}]",
                whitespace: "'${label}'不能为空字符"
            }
        }}
                        getPopupContainer={(triggerNode) => {
                            return triggerNode ? triggerNode.parentElement : document.body
                        }}

        >
            <Layout className={"admin-wrapper"} >
                <Sider theme={"dark"} width={180} >
                    <AdminMenu postData={getBreadCrumbData} />
                </Sider >
                <Layout >
                    <Header >
                        <AdminHeader breadCrumbData={breadCrumbData} />
                    </Header >
                    <Content className="main-wrap" >
                        <Routes auth={user} />
                    </Content >
                </Layout >
            </Layout >
        </ConfigProvider >
    );
}

export default Admin;
