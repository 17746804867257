import React, {useEffect} from 'react';
import {Avatar, Col, Dropdown, Menu, Row} from "antd";

import {PoweroffOutlined} from "@ant-design/icons";

import avatarImg from "../../../style/imgs/admin/default_avatar.jpg"
import AdminBreadCrumb from "./AdminBreadCrumb";


const AdminHeader = ({breadCrumbData}) => {

    useEffect(() => {

    }, [breadCrumbData])


    return (
        <div className={"admin-header"} >
            <Row justify={"space-between"} >
                <Col >
                    <AdminBreadCrumb data={breadCrumbData || []} />
                </Col >
                <Col >
                    <Dropdown placement={"bottomLeft"} overlay={
                        <Menu >
                            <Menu.Item key={"logout"} icon={<PoweroffOutlined />} >登出</Menu.Item >
                        </Menu >
                    } >
                        <div >
                            <Avatar src={avatarImg} />
                        </div >
                    </Dropdown >
                </Col >
            </Row >


        </div >
    );
}

AdminHeader.propTypes = {};

export default AdminHeader;