import React, {useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";

import adminLogo from "../../../style/imgs/home/galaxy_logo_white.png"
import {Menu} from "antd";

const AdminMenu = ({postData}) => {
    const history = useHistory();
    const [selectedKeys, setSelectedKeys] = useState([]);

    useEffect(() => {
        setSelectedKeys([history.location.pathname])
        const breadCrumbData = menuList.filter(item => item.key === history.location.pathname);
        postData(breadCrumbData)
    }, [])


    const menuList = [
        {
            label: "首页",
            key: "/AdminSystem/Home"
        }, {
            label: "资讯管理",
            key: "/AdminSystem/News"
        },
    ]


    /**
     * 菜单点击
     * @param e
     */
    const menuChange = (e) => {
        history.push({pathname: e.key})
        setSelectedKeys(e.key)
        const breadCrumbData = menuList.filter(item => item.key === e.key);
        postData(breadCrumbData)
    }


    return (
        <div className={"admin-sider"} >
            <div >
                <img className="admin-logo" src={adminLogo} alt="" />
                <Menu mode="inline" theme="dark" onClick={menuChange} selectedKeys={selectedKeys} >
                    {
                        menuList.map(item => (
                            <Menu.Item key={item.key} >{item.label}</Menu.Item >
                        ))
                    }
                </Menu >
            </div >


        </div >
    );
}

AdminMenu.propTypes = {};

export default AdminMenu;