/**
 * Created by marshal on 2017/5/7.
 */
import React from 'react';
import {Button, Result, Space} from "antd";
import {HomeOutlined, RollbackOutlined} from "@ant-design/icons";
import {Common} from "../../axios";


class NotFound extends React.Component {

    state = {
        animated: ''
    };
    enter = () => {
        this.setState({animated: 'hinge'})
    };

    goIndex = () => {
        this.props.history.push({pathname: "/app/homepage/HomepageManageIndex"})
    }

    render() {
        return (

            <div className="center text-center" style={{height: '100%', background: '#fff', overflow: 'hidden'}} >
                <Result
                    status="404"
                    title="404"
                    subTitle="抱歉，没有找到这个页面。"
                    extra={
                        <Space >
                            <Button icon={<HomeOutlined />} onClick={this.goIndex} >首页 </Button >
                            <Button icon={<RollbackOutlined />} onClick={() => Common.goPrevPage(this)}
                                    type={"primary"} ghost >返回 </Button >
                        </Space >
                    }
                />
                {/*
            <img src={img} alt="404" className={`animated swing ${this.state.animated}`}
                 onMouseEnter={this.enter} />
                 */}
            </div >
        )
    }

}

export default NotFound;