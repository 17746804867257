import React from 'react';
import {Button, Menu, Space} from "antd";
import {Link, useHistory} from "react-router-dom";
import logoImg from "../../style/imgs/home/galaxy_logo_white.png"

const HeaderCustom = () => {
    const history = useHistory();

    const menuList = [
        {
            title: "首页",
            key: "/"
        }, {
            title: "产品介绍",
            key: "/ProductDetail"
        },
        {
            title: "产品价格",
            key: "/ProductPrice"
        },
        {
            title: "解决方案",
            key: "/Solution"
        },
        {
            title: "新闻中心",
            key: "/News"
        },
        {
            title: "关于我们",
            key: "/AboutUs"
        }
    ]

    const onMenuClick = (e) => {
        console.log('click ', e);
        const path = e.key;
        history.push({
            pathname: path
        })
    }

    return (
        <div className={"header"} >
            <div className={"left"} >
                <Link to={"/"} >
                    <img src={logoImg} className={"home-logo"} alt="LOGO" />
                    <span className={"logo-text"}>星舰</span>
                </Link >
            </div >
            <div className={"middle"} >
                <Menu onClick={onMenuClick}
                      mode={"horizontal "}
                      theme={"light"}
                >
                    {
                        menuList.map((item) =>
                            <Menu.Item key={item.key} >
                                {item.title}
                            </Menu.Item >
                        )
                    }

                </Menu >
            </div >
            <div className={"right"} >
                <Space size={"large"} >
                    <a href="https://erp.galaxyfor.com" target={"_blank"} style={{color: '#fff'}} rel="noopener noreferrer">登录</a >
                    <Button type={"primary"} ><a href="https://erp.galaxyfor.com" target={"_blank"} rel="noopener noreferrer">体验demo</a></Button >
                </Space >
            </div >
        </div >
    );
}

HeaderCustom.propTypes = {};

export default HeaderCustom;