import React, {useState, useEffect} from 'react';
import {BackTop, ConfigProvider, Layout} from 'antd';
import Routes from './routes/Route';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import 'antd/dist/antd.less'
import './style/index.less';
import moment from 'moment';
import 'moment/locale/zh-cn';
import HeaderCustom from "./components/tag_common/HeaderCustom";
import FooterCustom from "./components/tag_common/FooterCustom";
import BackTopPreItem from "./components/tag_common/BackTopPreItem";
import {ArrowUpOutlined} from "@ant-design/icons"

// 推荐在入口文件全局设置 locale
moment.locale('zh-cn');
const {Content, Header, Footer} = Layout;

const App = ({user}) => {
    const [hidden, setHidden] = useState(false)
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, [])
    const handleScroll = (event) => {
        let scrollTop = document.documentElement.scrollTop;  //滚动条滚动高度
        if (scrollTop > 300) {
            setHidden(true)
        } else {
            setHidden(false)
        }
    }

    return (
        <ConfigProvider locale={zhCN} form={{
            validateMessages: {
                required: "'${label}'不能为空",
                default: "'${label}' 输入有误,请检查",
                enum: "'${label}' 必段属于 [${enum}]",
                whitespace: "'${label}'不能为空字符"
            }
        }}
        >
            <Layout >
                <Layout >
                    <Header style={{opacity: hidden ? 0 : 1}} >
                        <HeaderCustom />
                    </Header >
                    <Content className="main-wrap" >
                        <BackTopPreItem/>
                        <BackTop >
                            <div className={"back-top-grid"}>
                                <ArrowUpOutlined />
                            </div>
                        </BackTop>
                        <Routes auth={user} />
                    </Content >
                    <Footer >
                        <FooterCustom />
                    </Footer >
                </Layout >
            </Layout >
        </ConfigProvider >
    );
}

export default App;
