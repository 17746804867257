import React from 'react';
import {Col, Descriptions, Row} from "antd";
import qrCodeImg from "../../style/imgs/home/u106.png"
import gongAnImg from "../../style/imgs/home/gongan.png"

const FooterCustom = () => {
    return (
        <div >
            <div className={"footer"} >
                <div className={"auto-content"} >
                    <Row gutter={[24, 12]} >
                        <Col xs={24} sm={12} >
                            <Descriptions title={"联系我们"} column={1} >
                                <Descriptions.Item label={"电子邮箱"} >hr@chinabzc.com</Descriptions.Item >
                                <Descriptions.Item label={"公司地址"} >广州市黄埔区科学大道8号励弘文创旗舰园G栋6楼</Descriptions.Item >
                            </Descriptions >
                        </Col >
                        <Col xs={24} sm={12} >
                            <div className={"flex-row space-between"} >
                                <Descriptions title={"电话咨询"} column={1} >
                                    <Descriptions.Item label={"公司固话"} >020-89858579</Descriptions.Item >
                                    <Descriptions.Item label={"商务合作"} >13802410343</Descriptions.Item >
                                </Descriptions >
                                <div className={"qr-code"} >
                                    <img src={qrCodeImg} alt="" />
                                </div >
                            </div >
                        </Col >
                    </Row >
                </div >

            </div >
            <div className={"footer-info"} style={{fontSize: 12}} >
                Copyright © 2021-2022 广州佰仲光伏科技
                <img src={gongAnImg} style={{width: 20, height: "auto",marginLeft:10}} alt="" />
                <a style={{marginLeft: 5, color: "#fff"}} href="https://beian.miit.gov.cn"
                   target={"_blank"} rel="noopener noreferrer">粤ICP备2021088819号</a >
            </div >
        </div >
    );
}

FooterCustom.propTypes = {};

export default FooterCustom;