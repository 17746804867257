import axios from 'axios';
import md5 from './md5';
import {Modal, Select} from 'antd'
import {Tool} from "../components/tag_common/Tool";
import {Login} from "./login";
import moment from 'moment';
import React from "react";


// export const baseurl = 'http://47.103.215.237:8902';
export const fastdfsurl = 'http://101.132.173.115/';
export const wsurl = "ws://localhost:8105/websocket";
// export const baseurl = 'http://localhost:8101';//测试
export const baseurl = 'https://zb.lehuicraft.cn/lehuitestserver';//测试
//export const baseurl = 'https://www.chinabzc.com/zhaoshangwechat';//域名测试地址
export const appkey = "lehuialibaba202011@bzc";
export const versioncode = "1.0";
export const clientid = "web";
export const clientsecret = "webApp";


axios.defaults.baseURL = baseurl;
axios.defaults.timeout = 24000;
// axios.defaults.transformRequest=function (data) {
//     return data;
// }
//添加一个请求拦截器
axios.interceptors.request.use(function (config) {
    //在请求发出之前进行一些操作baseurl.indexOf("https")
    if (config != null) {
        let ts = Math.ceil(Math.random() * 100000000);
        let utoken = "";
        let currentusr = Common.getLoginUser();
        if (currentusr != null && currentusr != "" && currentusr != "null") {
            // currentusr = JSON.parse(currentusr);
            if (currentusr.utoken == null || typeof currentusr.utoken == "undefined") {
                utoken = uuid(8, 16);
            } else {
                utoken = currentusr.utoken;
            }
        } else {
            utoken = uuid(8, 16);
        }
        let okey = utoken + ts + appkey;
        let skey = md5.encodeByMD5in16(okey);
        let isfile = 0;
        let heads = {
            "clientid": clientid,
            "clientsecret": clientsecret,
            'isfile': isfile,
            'utoken': utoken,
            'skey': skey,
            'versioncode': versioncode,
            'platform': 'web',
            'ts': ts
        }
        config.headers = heads;
    }
    return config;
}, function (err) {
    //Do something with request error
    return Promise.reject(err);
});
//添加一个响应拦截器
let isnoauths = 0;//多次权限校验不通过时，只弹出一个提示框
axios.interceptors.response.use(function (res) {
    //在这里对返回的数据进行处理
    let returndata = res.data;
    if (isnoauths == 0) {
        if (returndata != null) {
            if (returndata.state == 402) {
                isnoauths = 1;
                const modal = Modal.error({
                    title: '提示',
                    content: returndata.showmsg,
                    width: 330,
                });
                setTimeout(() => restoreisnoauths(modal), 3000);
            } else if (returndata.state == 401 || returndata.state == 407 || returndata.state == 4110) {
                Tool.alerterror(returndata.showmsg);
                setTimeout(() => relogin(), 1000);
                localStorage.setItem('user', null);//清除缓存
            } else if (returndata.state == 6000) {
                if (isnoauths == 0) {
                    isnoauths = 1;
                    const modal = Modal.info({
                        title: '提示',
                        content: returndata.showmsg,
                        width: 330,
                    });
                    setTimeout(() => restoreisnoauths(modal), 1000);
                    Common.removeItem();
                }
                localStorage.setItem('user', null);//清除缓存
            } else if (returndata.state != null && returndata.state != 0 && returndata.state != 1) {
                //if(Common.devdebug){
                if (returndata.showmsg != null && returndata.msg != null) {
                    const modal = Modal.info({
                        title: '提示',
                        content: returndata.msg,
                        width: 330,
                    });
                    setTimeout(() => restoreisnoauths(modal), 5000);
                } else if (returndata.showmsg != null) {
                    const modal = Modal.info({
                        title: "提示",
                        content: returndata.showmsg,
                        width: 330,
                    });
                    setTimeout(() => restoreisnoauths(modal), 3000);
                }
                // }else{
                //     if(returndata.state == 200){
                //         const modal = Modal.info({
                //             title: "提示",
                //             content: "网络异常,请稍后重试!",
                //             width: 330,
                //         });
                //         setTimeout(() => restoreisnoauths(modal), 3000);
                //     }else{
                //         const modal = Modal.info({
                //             title: "提示",
                //             content: returndata.state+",状态码未定义,请联系运维!",
                //             width: 330,
                //         });
                //         setTimeout(() => restoreisnoauths(modal), 3000);
                //     }
                // }
            }
        }
    }
    return res;
}, function (err) {
    //Do something with response error
    //todo 先隐藏掉报错
    // Tool.alerterror("网络错误,请检查网络连接!");
    // return Promise.resolve();
    return Promise.reject(err);
})
// axios.defaults.headers["Access-Control-Allow-Origin"] = "*";//不能加这个，加了这个会发起两次请求，特别注意
export const restoreisnoauths = (modal) => {
    isnoauths = 0;
    modal.destroy();
}
export const relogin = () => {
    window.location.href = "/";
}

export const uuid = (len, radix) => {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    let uuid = [], i;
    radix = radix || chars.length;

    if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
        // rfc4122, version 4 form
        let r;

        // rfc4122 requires these characters
        uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
        uuid[14] = '4';

        // Fill in random data. At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | Math.random() * 16;
                uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
            }
        }
    }

    return uuid.join('');
}
export const InitUrl = (url, pisfile) => {

    let isurlhave = 0;
    if (url.indexOf("?") > 0) {
        isurlhave = 1;
    }
    let ts = Math.ceil(Math.random() * 100000000);
    let utoken = "";
    let currentusr = Common.getLoginUser();
    if (currentusr != null && currentusr != "" && currentusr != "null") {
        if (currentusr.utoken == null || typeof currentusr.utoken == "undefined") {
            utoken = uuid(8, 16);
        } else {
            utoken = currentusr.utoken;
        }

    } else {
        utoken = uuid(8, 16);
    }
    let platform = "web";
    let okey = utoken + ts + appkey;
    let skey = md5.encodeByMD5in16(okey);
    let isfile = 0;
    if (pisfile != null && pisfile == true) {
        isfile = 1;
    }

    let extendurl = (isurlhave == 1 ? '&' : '?') + 'clientid=' + clientid + '&clientsecret=' + clientsecret + '&isfile=' + isfile + '&utoken=' + utoken + "&skey=" + skey + "&versioncode=" + versioncode + "&ts=" + ts;
    url += extendurl;
    return encodeURI(url);//encodeURI：解决IE浏览器导出execl时url包含中文字符会乱码
}


export const GetSkey = (okey, ts, utokey) => {
    let tempskey = utokey + ts + okey;

}

const arrBuildIn = (doinfo, arr, count) => {
    if (count < arr.length - 1) {
        if (doinfo[arr[count]] == null) {
            doinfo[arr[count]] = {};
        }
        doinfo[arr[count]] = arrBuildIn(doinfo[arr[count]], arr, count++);
    } else {
        return doinfo;
    }
}
/**
 * 循环构建option
 */
const forchildoptions = (infos, labelclm, valueclm, childrenclm) => {
    var returnoptions = [];
    if (infos != null) {
        for (let j = 0; j < infos.length; j++) {
            let returnoption = {};
            returnoption["label"] = infos[j][labelclm];
            returnoption["value"] = infos[j][valueclm];
            if (childrenclm != null && infos[j][childrenclm] != null) {
                returnoption["children"] = forchildoptions(infos[j][childrenclm], labelclm, valueclm, childrenclm);
            }
            returnoptions.push(returnoption);
        }
    }
    return returnoptions;
}

export const Common = {
    intorfloattwo: /(^[0-9]{1,6}$)|(^[0-9]{1,6}[\.]{1}[0-9]{1,2}$)/,
    int: /(^[0-9]{1,6}$)/,
    currentuser: null,
    /*************空判断-开始****************/
    /**
     * 判断对象是否为空
     * @param obj
     * @returns {boolean}
     */
    isEmpty: function (obj) {
        for (let name in obj) {
            return false;
        }
        return true;
    },
    //校验正整数
    checkPositiveInteger: function (value) {
        return /^([0]|[1-9][0-9]*)$/.test(value);
    },
    /**
     * 判断字符串是否为空
     * @param str
     * @returns {boolean}
     */
    isStrEmpty(str) {
        return str === null || str === undefined || str.length === 0 || str === "";
    },
    /**
     * 判断字符串是否不为空
     * @param str
     * @returns {boolean}
     */
    isStrNotEmpty(str) {
        return !this.isStrEmpty(str);
    },

    strEmptyFilter(str) {
        if (str == null) {
            return "";
        }
        return str;
    },

    /**
     * 判断对象是数组，且不是空的数组
     * @param {Array} arr 要判断的数组
     */
    isNotEmptyArray(arr) {
        return Array.isArray(arr) && arr.length > 0;
    },
    /**
     * 判断对象是数组，且是空的数组
     * @param {Array} arr 要判断的数组
     */
    isEmptyArray(arr) {
        return Array.isArray(arr) && arr.length === 0;
    },

    /**
     * 判断对象是否为空
     * @param obj
     * @returns {boolean}
     */
    isEmptyObject(obj) {
        if (!obj) return true;
        if (Object.keys(obj).length === 0) {
            return true;
        }
        return false;
    },
    /**
     * 判断对象是否不为空
     * @param obj
     * @returns {boolean}
     */
    isNotEmptyObject(obj) {
        return !this.isEmptyObject(obj);
    },
    /************空判断-结束******************************************用户相关操作-开始********************/
    showvalue(info, field) {
        if (info != null && field != null) {
            return info[field];
        }
        return "";
    },
    /**
     * 生成加密密码
     */
    md5Password(pass) {
        return md5(pass + Common.salt).toUpperCase();
    },
    /**
     * 返回登录页
     */
    removeItem: function () {
        Login.dologout().then(res => {
            localStorage.removeItem('user');
            this.currentuser = null;
            window.location.href = "/";
        })
    },
    /**
     * 获取当前登录用户姓名
     */
    getCurrentUserName() {
        const user = this.getLoginUser();
        return user.loginname;
    },
    /**
     * 获取当前用户ID
     */
    getCurrentUserId() {
        const user = this.getLoginUser();
        return user.id;
    },
    /**
     * 获取当前用户的企业类型
     */
    getCurrentOrgType() {
        const user = this.getLoginUser();
        return user.orgtype;
    },
    /**
     * 获取当前用户的企业ID
     */
    getCurrentEnterpriseId() {
        const user = this.getLoginUser();
        return user.enterpriseid;
    },

    /**
     * 获取当前登录用户信息
     */
    getLoginUser: function () {
        if (this.currentuser != null) {
            return this.currentuser;
        }
        let currentusr = localStorage.getItem('user');//localStorage.getItem(key):获取指定key本地存储的值,localStorage是本地存储
        if (currentusr != null) {
            currentusr = JSON.parse(currentusr);//JSON.parse 将一串JSON文本变成一个Javascript对象。
        }
        return currentusr;
    },

    /**
     * 权限判断
     * @param value 权限编码
     * @returns {boolean}
     */
    checkUserAuth: function (value) {
        if (value && value.length > 0) {
            let isauth = Common.checkAuths(value);
            if (isauth == false) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    },
    /**
     * 判断用户的权限
     * @param auths 权限编码
     * @returns {boolean}
     */
    checkAuths: function (auths) {
        let isauth = false;
        let user = JSON.parse(localStorage.getItem('user')); //获取当前登录用户的权限列表
        let authlist = new Array();
        if (user != null && user.rolerelationpermissionlist != null && user.rolerelationpermissionlist.length > 0) {
            authlist = user.rolerelationpermissionlist;
            for (let i = 0; i < authlist.length; i++) {
                if (auths == authlist[i].permissionid) {
                    isauth = true;
                    break;
                }
            }
        }
        return isauth;
    },
    /**
     * 判断用户的权限
     * @param user  用户信息
     * @param auths   权限编码
     * @returns {boolean}
     */
    checkUserAuths: function (user, auths) {
        let isauth = false;
        let authlist = new Array();
        if (user != null && user.rolerelationpermissionlist != null && user.rolerelationpermissionlist.length > 0) {
            authlist = user.rolerelationpermissionlist;
            for (let i = 0; i < authlist.length; i++) {
                if (auths == authlist[i].permissionid) {
                    isauth = true;
                    break;
                }
            }
        }
        return isauth;
    },

    /**
     * 检查后台返回的结果
     * @param result
     * @returns {*}
     */
    checkResult: function (result) {
        if (result.status === 200) {
            const {state, showmsg, msg} = result.data;
            // if (Common.devdebug) {
            //     if (state !== 1 && state !== 0) {
            //         Tool.alerterror(showmsg);
            //     }
            // }
            if (state === 0) {
                Tool.alerterror(showmsg || msg);
            }
            return result.data;
        } else {
            Tool.alerterror("请检查网络连接");
            return {state: -1};
        }
    },
    /**
     * 判断后台返回的状态是否是成功
     * @param state
     * @returns {boolean}
     */
    checkStateSuccess: function (state) {
        if (state != null && state == Common.StateCode.Success.value) {
            return true;
        }
        return false;
    },


    /**
     * 比较两个数的大小
     * @param upper
     * @param lower
     * @returns {number}
     */
    compareParseFloat: function (upper, lower) {
        let value1 = upper != null ? upper : 0;
        let value2 = lower != null ? lower : 0;
        if (parseFloat(value1) < parseFloat(value2)) {
            return -1;
        } else if (parseFloat(value1) > parseFloat(value2)) {
            return 1;
        } else {
            return 0;
        }
    },
    /************用户相关操作-结束******************************************缓存与URL参数-开始********************/

    /**
     * 设置标题
     */
    setDocumentTitle(title) {
        document.title = title;
    },
    /**
     * 把数据存入localstorage
     * @param {string} key 键值对中的键
     * @param {Object} value 键值对中的值
     */
    setData(key, value) {
        const val = JSON.stringify(value);
        localStorage.setItem(key, val);
    },
    /**
     * 移除localstorage中的数据
     * @param {string} key 键值对中的值
     */
    removeData(key) {
        localStorage.removeItem(key);
    },

    /**
     * 这个是获取url中传递过来的参数,返回的是一个数组或者一个空值
     * @param searchurl    url
     * @param name      参数
     * @returns {*}
     */
    getQueryString: function (searchurl, name) {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        let r = searchurl.substr(1).match(reg);
        if (r != null) return (r[2]);
        return null;
    },
    /**
     * 获取url中"?"符后的字串
     * @param url
     * @returns {Object}
     * @constructor
     */
    GetRequest(url) {
        let theRequest = new Object();
        if (url.indexOf("?") != -1) {
            let str = url.substr(1);
            let strs = str.split("&");
            for (let i = 0; i < strs.length; i++) {
                theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
            }
        }
        return theRequest;
    },

    /**
     * 获取连接中的指定key 的值
     * @param {string} name 连接参数的key
     */
    getQueryStringByKey(name) {
        const url = window.location.href;
        const urls = url.split('?');
        if (urls.length !== 2) {
            return null;
        }
        const queryStr = urls[1];
        const queryItemStr = queryStr.split('&');
        for (let i = 0; i < queryItemStr.length; ++i) {
            const item = queryItemStr[i];
            const itemSplit = item.split('=');
            const key = itemSplit[0];
            if (key === name) {
                return itemSplit[1];
            }
        }
        return null;
    },

    /**
     * 获取连接中的参数，返回对象
     * @returns {null}
     */
    getQueryData() {
        const url = window.location.href;
        const urls = url.split('?');
        if (urls.length !== 2) {
            return null;
        }
        const queryStr = urls[1];
        const queryItemStr = queryStr.split('&');
        const data = {};
        for (let i = 0; i < queryItemStr.length; ++i) {
            const item = queryItemStr[i];
            const itemSplit = item.split('=');
            const key = itemSplit[0];
            data[key] = itemSplit[1];
        }
        return data;
    },
    /**
     * 返回上一页
     * @param _this 当前页面this
     */
    goPrevPage: function (_this) {
        if (typeof _this === 'undefined' || _this == null) {
            const modal = Modal.error({
                title: '错误',
                content: '返回页面参数不足',
                width: 330,
            });
            setTimeout(() => restoreisnoauths(modal), 3000);
            return;
        }
        let prevState = _this.props.location.prevState;
        if (typeof prevState !== 'undefined' && prevState != null) {
            let path = {
                pathname: prevState.pathname,
                initState: prevState,
                prevState: prevState.grandState,
            }
            _this.props.history.push(path);
        } else {
            _this.props.history.go(-1);
        }
    },
    /**
     * 跳转下一页（自动构造prevState）
     * @param _this 当前页面this
     * @param path 跳转地址
     */
    goNextPage: function (_this, path, searchForm) {
        if (typeof _this === 'undefined' || _this == null || typeof path.pathname === 'undefined') {
            const modal = Modal.error({
                title: '错误',
                content: '参数不足',
                width: 330,
            });
            setTimeout(() => restoreisnoauths(modal), 3000);
            return;
        }
        path = Object.assign(path, {
            prevState: Object.assign(_this.state, {
                pathname: _this.props.location.pathname,
                grandState: _this.props.location.prevState, // 记住祖父级state
                searchForm: searchForm,
            })
        });
        _this.props.history.push(path);
    },
    CreateSelectKey: (list) => {
        if (list != null && list.length > 0) {
            return list.map(item => item.key);
        }
        return [];

    },
    /*
     创建分页
    */
    CreatePageOption: (stocksearchmodel, pageChange, footer) => {
        if (stocksearchmodel.pageSize == null) {
            stocksearchmodel.pageSize = 50;
        }
        if (stocksearchmodel.page == null || stocksearchmodel.page == 0) {
            stocksearchmodel.page = 1;
        }
        let result = {  //分页
            total: stocksearchmodel.count, //数据总数量
            pageSize: stocksearchmodel.pageSize,  //显示几条一页
            defaultPageSize: stocksearchmodel.pageSize,
            showSizeChanger: true,  //是否显示可以设置几条一页的选项
            defaultCurrent: stocksearchmodel.page,
            current: stocksearchmodel.page,
            showTotal: function (total, range) {
                return <div>
                    <div style={{display: "inline-block", marginRight: 60}}>{footer}</div>
                    共 {total} 条数据 显示{range[0]} 到 {range[1]}</div>;
            },
            pageSizeOptions: ['15', '30', '50', '100'],
            onChange: (page, pageSize) => {
                stocksearchmodel.page = page;
                stocksearchmodel.pageSize = pageSize;
                if (pageChange != null) {
                    pageChange(stocksearchmodel);
                }
            },
            onShowSizeChange: (current, size) => {
                stocksearchmodel.page = current;
                stocksearchmodel.pageSize = size;
                if (pageChange != null) {
                    pageChange(stocksearchmodel);
                }
            },
        }
        return result;
    },
    CreatePage: (that, pageChange, pagekey, footer) => {
        let stocksearchmodel = null;
        if (pagekey != null && that.state.searchModel != null) {
            stocksearchmodel = that.state.searchModel[pagekey];
        } else {
            stocksearchmodel = that.state.searchModel;
        }
        if (stocksearchmodel == null) {
            stocksearchmodel = Common.getCommSearch();
        }

        if (stocksearchmodel.pageSize == null) {
            stocksearchmodel.pageSize = 15;
        }
        if (stocksearchmodel.page == null || stocksearchmodel.page == 0) {
            stocksearchmodel.page = 1;
        }
        let result = {  //分页
            total: stocksearchmodel.count, //数据总数量
            pageSize: stocksearchmodel.pageSize,  //显示几条一页
            defaultPageSize: stocksearchmodel.pageSize,
            showSizeChanger: true,  //是否显示可以设置几条一页的选项
            defaultCurrent: stocksearchmodel.page,
            current: stocksearchmodel.page,
            showTotal: function (total, range) {
                return <div>
                    <div style={{display: "inline-block", marginRight: 60}}>{footer}</div>
                    共 {total} 条数据 显示{range[0]} 到 {range[1]}</div>;
            },
            pageSizeOptions: ['15', '30', '50', '100'],
            onChange: (page, pageSize) => {
                stocksearchmodel.page = page;
                stocksearchmodel.pageSize = pageSize;
                // let keysearch={};
                // keysearch[pagekey]=stocksearchmodel;
                // that.setState({searchModel:keysearch});
                if (pageChange != null) {
                    pageChange(stocksearchmodel);
                }
            },
            onShowSizeChange: (current, size) => {
                stocksearchmodel.page = current;
                stocksearchmodel.pageSize = size;
                // let keysearch={};
                // keysearch[pagekey]={searchModel:stocksearchmodel};
                // that.setState(keysearch);
                if (pageChange != null) {
                    pageChange(stocksearchmodel);
                }
            },
        }
        return result;
    },
    /**
     * searchmodele 和searchdata要严格分开
     * @param that
     * @param pagekey
     * @param searchmodel
     * @param searchdata
     */
    resultSetSearch: function (that, pagekey, searchmodel, searchdata) {
        searchmodel.searchdata = searchdata;
        let searchM = {searchModel: that.state.searchModel};
        searchM.searchModel[pagekey] = searchmodel;
        that.setState(searchM);
    },
    InitGetSearchState: function (that) {
        let initState = that.props.location.initState;
        if (initState != null && initState.searchModel != null) {
            that.setState({
                searchModel: initState.searchModel
            })
        } else {
            initState = that.state;
        }
        return initState;
    },
    /**
     * 将存储的对象使用JSON的方式进行存储
     * @param key     键
     * @param searchinfo      值
     */
    saveDefaultSearch: function (key, searchinfo) {
        if (key != null && searchinfo != null) {
            localStorage.setItem(key, JSON.stringify(searchinfo));
        }
    },
    /**
     * 根据键获取指定的缓存值
     * @param key    键
     * @returns {any}   值
     */
    getDefaultSearch: function (key) {
        let searchinfo = JSON.parse(localStorage.getItem(key));
        return searchinfo;
    },
    /**
     * 获取默认SearchModel
     * @param that
     * @returns {*}
     */
    getSearchModel: function (that) {
        let initState = null;
        if (!Common.isEmpty(that.props.location.initState)) {
            initState = that.props.location.initState;
        }
        return initState ? initState.searchModel : this.getCommSearch()
    },
    getCommSearch: function () {
        return {    //设置最初一页显示多少条
            page: 1,
            pageSize: 50
        };
    },
    /**
     * 设置附件预览地址为url（主要是兼容没有保存的附件也能预览）
     * @param filelist
     */
    setFilelist: function (filelist) {
        if (filelist != null && filelist.length > 0) {
            for (var i = 0; i < filelist.length; i++) {
                let currentobj = filelist[i];
                if (currentobj != null && currentobj.fastdfspath != null && currentobj.fastdfspath.length > 0) {
                    currentobj.uid = currentobj.documentid;
                    currentobj.name = currentobj.documentname;
                    currentobj.url = currentobj.fastdfspath;
                    currentobj.status = 'done';
                }
            }
        }
        return filelist;
    },
    /************缓存与URL参数-结束******************************************常规校验-开始********************/



    /**
     * 检查是否是座机
     */
    isCellPhone: function (mobile) {
        let mobileReg = /^(0[0-9]{2,3}-)?([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/;
        //香港和国外的座机
        let otherReg = /^((((0?)|((00)?))(((\s){0,2})|([-_－—\s]?)))|(([(]?)[+]?))(852)?([)]?)([-_－—\s]?)((2|3|5|6|9)?([-_－—\s]?)\d{3})(([-_－—\s]?)\d{4})$/;
        return mobileReg.test(mobile) || otherReg.test(mobile);
    },
    /**
     * 检查是否是手机号码
     */
    isMobile: function (mobile) {
        let mobileReg = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
        return mobileReg.test(mobile);
    },
    /**
     * 检查是手机或座机
     */
    isPhone: function (mobile) {
        let result = false;
        if (this.isMobile(mobile) || this.isCellPhone(mobile)) {
            result = true;
        }
        return result;
    },

    /**
     * 精简滚动计算字符串
     * @returns {{x, y: string}}
     * @param x {number,null,''}
     * @param y {number,null,''}
     */
    getScroll: function (x, y) {
        let _obj = {};
        if (x) {
            _obj['x'] = x
        }
        if (y) {
            _obj['y'] = "calc(100vh - " + y + "px)"
        }
        return _obj
    },

    /**
     * 检查是否为邮箱
     */
    isEmail: function (email) {
        let p = /^[_\.0-9a-z-]+@([0-9a-z][0-9a-z-]+\.){1,4}[a-z]{2,3}$/i;
        return p.test(email);
    },
    /**
     * 校验身份证号码有效性
     * @param code  身份证号码
     * @returns {{msg: string, pass: boolean}}
     * @constructor
     */
    IdCodeValid: function (code) {
        //身份证号合法性验证
        //支持15位和18位身份证号
        //支持地址编码、出生日期、校验位验证
        let city = {
            11: "北京",
            12: "天津",
            13: "河北",
            14: "山西",
            15: "内蒙古",
            21: "辽宁",
            22: "吉林",
            23: "黑龙江 ",
            31: "上海",
            32: "江苏",
            33: "浙江",
            34: "安徽",
            35: "福建",
            36: "江西",
            37: "山东",
            41: "河南",
            42: "湖北 ",
            43: "湖南",
            44: "广东",
            45: "广西",
            46: "海南",
            50: "重庆",
            51: "四川",
            52: "贵州",
            53: "云南",
            54: "西藏 ",
            61: "陕西",
            62: "甘肃",
            63: "青海",
            64: "宁夏",
            65: "新疆",
            71: "台湾",
            81: "香港",
            82: "澳门",
            91: "国外 "
        };
        let row = {
            'pass': true,
            'msg': '验证成功'
        };
        if (!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|[xX])$/.test(code)) {
            row = {
                'pass': false,
                'msg': '身份证号格式错误'
            };
        } else if (!city[code.substr(0, 2)]) {
            row = {
                'pass': false,
                'msg': '身份证号地址编码错误'
            };
        } else {
            //18位身份证需要验证最后一位校验位
            if (code.length == 18) {
                code = code.split('');
                //∑(ai×Wi)(mod 11)
                //加权因子
                let factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
                //校验位
                let parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
                let sum = 0;
                let ai = 0;
                let wi = 0;
                for (let i = 0; i < 17; i++) {
                    ai = code[i];
                    wi = factor[i];
                    sum += ai * wi;
                }
                if (parity[sum % 11] != code[17].toUpperCase()) {
                    row = {
                        'pass': false,
                        'msg': '身份证号校验位错误'
                    };
                }
            }
        }
        return row;
    },
    InputNumberParserInt: function (val) {
        if (isNaN(Number(val))) {
            return 0;
        }
        return val != "" ? parseInt(val) : val;
    },
    checkdate: function (str) {
        if (str == '') return true;
        let reg = /^(?:(?!0000)[0-9]{4}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[0-9]{2}(?:0[48]|[2468][048]|[13579][26])|(?:0[48]|[2468][048]|[13579][26])00)-02-29)$/;
        return reg.test(str);//这个是检测是否符合这个正则表达式的
    },
    extendSearchModel: function (sourceobj, extendobj, needreplacetag) {
        if (sourceobj == null) {
            sourceobj = {};
        }
        if (extendobj != null) {
            if (needreplacetag != null) {
                for (var property in extendobj) {
                    //if(typeof extendobj[property]!="undefined" ) {
                    let replaceproperty = property.toString().replace(needreplacetag, "");
                    sourceobj[replaceproperty] = extendobj[property];
                }
            } else {
                for (var property in extendobj) {
                    // if(typeof extendobj[property]!="undefined" ) {
                    //     sourceobj[property] = extendobj[property];
                    // }
                    if (property.indexOf('.') > 0) {//层级解析，第一个字符为点不考虑
                        let arr = property.split('.');
                        if (sourceobj[arr[0]] == null) {
                            sourceobj[arr[0]] = {};
                        }
                        let inobj = arrBuildIn(sourceobj[arr[0]], arr, 1);
                        inobj[arr[arr.length - 1]] = extendobj[property];
                        sourceobj[arr[0]] = inobj;
                    } else {
                        sourceobj[property] = extendobj[property];
                    }
                }
            }
        }
    },
    /**
     * 构建options
     * @param infos
     * @param labelclm
     * @param valueclm
     * @param childrenclm
     */
    buildOptions: function (infos, labelclm, valueclm, childrenclm) {
        let options;
        if (infos != null) {
            options = forchildoptions(infos, labelclm, valueclm, childrenclm)
        }
        ;
        if (options == null || options.length == 0) {
            options = [{label: "无", value: 0}];
        }
        return options;
    },
    buildRangePickerValue: function (begintime, endtime) {
        var returnvales = [];
        if (begintime != null) {
            returnvales.push(moment(begintime));
        } else {
            returnvales.push(null);
        }
        if (endtime != null) {
            returnvales.push(moment(endtime));
        } else {
            returnvales.push(null);
        }
        return returnvales;
    },
    /**
     * 校验输入内容是否为英文[a-zA-Z]
     * @param str
     * @returns {boolean}
     */
    isEnglishString: function (str) {
        if (str == '') return true;
        let reg = /[a-zA-Z0-9_]/;
        return reg.test(str);
    },
    /**
     * 字符串是否包含指定字符
     * @param array 数组/字符串
     * @param item  字符
     * @returns {boolean}
     */
    isContains: function (array, item) {//是包含
        let iscontain = false;
        if (array != null && item != null) {
            for (let i = 0; i < array.length; i++) {
                if (array[i] == item) {
                    return true
                }
            }
        }
        return iscontain;
    },
    isNumOrLetter: function (str) {
        if (str == '') return true;
        let reg = /^(?!\d+$)[\da-zA-Z]+$/;
        return reg.test(str);
    },
    /**************常规校验-结束********************************************日期-开始*********************/

    /**
     * 不可选择的时间
     * @param record
     * @param type
     * @param value
     * @returns {boolean}
     */
    disabledDate: function (value) {
        return value && value < moment().endOf('day');
    },
    /**
     * 时间戳转换为指定格式的日期
     * @param type(返回的格式类型，枚举类：TimestampType 【1：2020-04-20 10:14:32】【2：2020-04-20】【3：04-20】【4：04-20 10:14】
     * @param timestamp 时间戳
     * @returns {string}
     */
    timestampToFormatTime: function (type, timestamp) {
        if (timestamp != null && timestamp != "") {
            let timestampstr = "";//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            if (timestamp > 9999999999) {
                timestampstr = timestamp;
            } else {
                timestampstr = parseInt(timestamp) * 1000;
            }
            let date = new Date(timestampstr);
            let Y = date.getFullYear() + '-';
            let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            let D = date.getDate() + ' ';
            let h = date.getHours();
            let m = ':' + date.getMinutes();
            let s = ':' + date.getSeconds();
            let restr = Y + M + D + h + m + s;
            if (type != null) {
                if (type == 1) {
                    restr = Y + M + D + h + m + s;
                } else if (type == 2) {
                    restr = Y + M + D;
                } else if (type == 3) {
                    restr = M + D;
                } else if (type == 4) {
                    restr = M + D + h + m;
                }
            }
            return restr;
        }
        return null;
    },
    /**
     * 时间戳转换为指定格式的日期
     * @param type(返回的格式类型，枚举类：TimestampType 【1：2020/4/20 10:14:32】【2：2020/4/20】【3：4/20】【4：4/20 10:14】
     * @param timestamp
     * @returns {string}
     */
    timestampFormarTime: function (type, timestamp) {
        if (timestamp != null && timestamp != "") {
            let timestampstr;//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            if (timestamp > 9999999999) {
                timestampstr = timestamp;
            } else {
                timestampstr = parseInt(timestamp) * 1000;
            }
            let date = new Date(timestampstr);
            let year = date.getFullYear();
            let mon = date.getMonth() + 1;
            let day = date.getDate();
            let hours = date.getHours();
            let minu = date.getMinutes();
            let sec = date.getSeconds();
            let restr = year + '-' + mon + '-' + day + ' ' + hours + ':' + minu + ':' + sec;
            if (type != null) {
                if (type == 1) {
                    restr = year + '-' + mon + '-' + day + ' ' + hours + ':' + minu + ':' + sec;
                } else if (type == 2) {
                    restr = year + '-' + mon + '-' + day;
                } else if (type == 3) {
                    restr = mon + '-' + day;
                } else if (type == 4) {
                    restr = mon + '-' + day + ' ' + hours + ':' + minu;
                }
            }
            return restr;
        }
        return null;
    },
    /**
     * 格式化日期
     * @param pdata     日期
     * @param fmt   日期格式
     * @returns {*}
     */
    formatDate: function (pdata, fmt) {
        let o = {
            "M+": pdata.getMonth() + 1, //月份
            "d+": pdata.getDate(), //日
            "h+": pdata.getHours(), //小时
            "m+": pdata.getMinutes(), //分
            "s+": pdata.getSeconds(), //秒
            "q+": Math.floor((pdata.getMonth() + 3) / 3), //季度
            "S": pdata.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (pdata.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (let k in o)
            if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    },
    /**
     * 格式化时间戳
     * @param timestamp  时间戳
     * @returns {*}
     */
    formatTimestampToSecondNull: function (timestamp) {
        if (timestamp != null && timestamp) {
            let showtime = null;
            if (timestamp > 9999999999) {
                showtime = timestamp;
            } else {
                showtime = timestamp * 1000;
            }
            return moment(showtime).format('YYYY-MM-DD HH:mm:ss');
        }
        return null;
    },
    /**
     * 格式化时间戳
     * @param timestamp  时间戳
     * @returns {*}
     */
    formatTimestampToDayNull: function (timestamp) {
        if (timestamp != null && timestamp) {
            let showtime = null;
            if (timestamp > 9999999999) {
                showtime = timestamp;
            } else {
                showtime = timestamp * 1000;
            }
            return moment(showtime).format('YYYY-MM-DD');
        }
        return null;
    },
    formatTimestamp: function (timestamp) {
        if (timestamp != null && timestamp) {
            return moment(timestamp).format('YYYY-MM-DD');
        }
        return "";
    },
    /**
     * 格式化时间戳
     * @param timestamp  时间戳
     * @returns {*}
     */
    formatTimestampToDay: function (timestamp) {
        if (timestamp != null && timestamp) {
            let showtime = null;
            if (timestamp > 9999999999) {
                showtime = timestamp;
            } else {
                showtime = timestamp * 1000;
            }
            return moment(showtime).format('YYYY-MM-DD');
        } else {
            return moment().format('YYYY-MM-DD');
        }
        return null;
    },

    /**
     * 在timestamp的基础上，往前推leadtime天
     */
    subtractMOment: function (timestamp, leadtime) {
        if (timestamp != null && timestamp) {
            let showtime = null;
            if (timestamp > 9999999999) {
                showtime = timestamp;
            } else {
                showtime = timestamp * 1000;
            }
            let wtf = moment(moment(moment(parseInt(showtime)).format('YYYY-MM-DD hh:mm:ss')).subtract(leadtime, 'days').calendar()).format("YYYY-MM-DD");
            return wtf
        }
        return null;
    },
    /**
     * 时间戳往前推day天天
     * @param timestamp 时间戳
     * @param day
     * @returns
     */
    timSubtractDay: function (timestamp, day) {
        if (timestamp != null && timestamp) {
            return moment(timestamp).subtract(day, 'days')._d.getTime();
        }
        return null;
    },
    /**
     * 时间戳增加天
     * @param timestamp 时间戳
     * @param day
     * @returns
     */
    timAddDay: function (timestamp, day) {
        if (timestamp != null && timestamp) {
            return moment(timestamp).add(day, 'days')._d.getTime();
        }
        return null;
    },

    /**
     * 在timestamp的基础上，往后推leadtime天
     */
    addMOment: function (timestamp, leadtime) {
        if (timestamp != null && timestamp) {
            let showtime = null;
            if (timestamp > 9999999999) {
                showtime = timestamp;
            } else {
                showtime = timestamp * 1000;
            }
            let wtf = moment(moment(moment(parseInt(showtime)).format('YYYY-MM-DD hh:mm:ss')).add(leadtime, 'days')).format("YYYY-MM-DD");
            return wtf
        }
        return null;
    },


    /**
     * 把日期转换为时间戳(DAY TO timestamp)
     */
    dayToTimestamp: function (day) {
        if (day != null && day) {
            return moment(day).valueOf() / 1000;
        }
        return null;
    },
    /**
     * 获取当前日期
     * @returns {string}
     */
    getToday: function () {
        let today = new Date();
        let datestr = (today.getFullYear()) + "年" + (today.getMonth() + 1) + "月" + today.getDate() + "日";
        return datestr;
    },
    /**
     * 获取当前时间戳（精确到毫秒）
     * @returns {string}
     */
    getTimestamp: function () {
        return moment(new Date()).valueOf();
    },

    /**
     * 获取短时间比如：2018-10-10 ，如果str 为空，则返回空字符串
     * @param {string} str 时间字符串
     */
    getShortDate(str) {
        if (this.isStrEmpty(str)) {
            return moment().format('YYYY-MM-DD');
        }
        if (str instanceof moment) {
            return str.format('YYYY-MM-DD');
        }
        return moment(str).format('YYYY-MM-DD');
    },
    /**
     * 获取短时间比如：2018-10-10 ，如果str 为空，则返回空字符串
     * @param {string} str 时间字符串
     */
    getShortDateWithOther(str) {
        if (this.isStrEmpty(str)) {
            return "";
        }
        if (str instanceof moment) {
            return str.format('YYYY-MM-DD');
        }
        // Aug 30, 2018 12:00:00 AM
        return moment(str, 'MMM DD, YYYY').format('YYYY-MM-DD');
    },
    /**
     * 把时间字符串转化为moment
     */
    convertDateStrToMoment(str) {
        if (this.isStrEmpty(str)) {
            return undefined;
        }
        return moment(str);

    },
    /**
     * 获取短时间比如：HH:mm ，如果str 为空，则返回当前时间
     * @param {string} str 时间字符串
     * @param {string} format 时间格式
     */
    getTime(str, format = 'HH:mm') {
        if (this.isStrEmpty(str)) {
            return moment().format(format);
        }
        if (str instanceof moment) {
            return str.format(format);
        }
        return moment(str).format(format);
    },
    /**
     * 获取长时间比如：2018-10-10 10:30
     * @param {string} str 时间字符串，如果str 为空，则返回当前时间
     */
    getLongDate(str) {
        if (this.isStrEmpty(str)) {
            return moment().format('YYYY-MM-DD HH:mm');
        }
        return moment(str).format('YYYY-MM-DD HH:mm');
    },
    /**
     * 获取长时间比如：2018-10-10 10:30:22
     * @param {string} str 时间字符串，如果str 为空，则返回当前时间
     * @returns {*}
     */
    getFullTime(str) {
        if (this.isStrEmpty(str)) {
            return moment().format('YYYY-MM-DD HH:mm:ss');
        }
        return moment(str).format('YYYY-MM-DD HH:mm:ss');
    },
    /**
     * 将\n等换行字符替换成<br/>标签 字符串换行 主要应用在Descriptions组件中
     * @param radioVal
     * @returns {moment.Moment[]|null|moment.Moment[]}
     */
    lineFeedStr(str) {
        if (str) {
            str = str.replace(/(\r\n)|(\n)/g, '<br/>')
            return <div dangerouslySetInnerHTML={{__html: str}}></div>
        } else {
            return "";
        }
    },

    convertDateWithRadio(radioVal) {
        switch (radioVal) {
            case '0': {
                // 昨天
                const date = moment().subtract(1, 'days');
                return [date, date];
            }
            case '1': {
                // 本周
                const date1 = moment().startOf('week');
                const date2 = moment().endOf('week');
                return [date1, date2];
            }
            case '2': {
                // 上周
                const date1 = moment().subtract(1, 'week').startOf('week');
                const date2 = moment().subtract(1, 'week').endOf('week');
                return [date1, date2];
            }
            case '3': {
                // 本月
                const date1 = moment().startOf('month');
                const date2 = moment().endOf('month');
                return [date1, date2];
            }
            case '4': {
                // 上月
                const lastMonthData1 = moment().subtract(1, 'month').startOf('month');
                const lastMonthData2 = moment().subtract(1, 'month').endOf('month');
                return [lastMonthData1, lastMonthData2];
            }
            case '5': {
                // 今天
                const date1 = moment();
                const date2 = moment();
                return [date1, date2];
            }
            default: {
                return null;
            }

        }
    },


    /**************日期-结束***********************************数值-开始***************/

    /**
     * 校验是否为正整数
     * @param str
     * @returns {boolean}
     * @constructor
     */
    IsIntThanZero: function (str) {
        let regax = /^[0-9]*[1-9][0-9]*$/;//匹配所有正整数
        return regax.test(str);
    },
    /**
     * 校验是否有效数值
     * @param str
     * @returns {boolean}
     */
    isFloat: function (str) {
        if (str == '') return true;
        let reg = /(^\d+([.]\d{0,2})?$)/;//匹配数字的,可匹配情况1.正负整数2.正负浮点数（带小数点的数）
        return reg.test(str);
    },
    IsFlortOrInt: function (str) {
        if (str == '') return true;
        let reg = /^[0-9]+([.]{1}[0-9]+){0,1}$/;
        return reg.test(str);
    },
    IsFloat: function (str) {
        if (str == '') return true;
        let reg = /^-?([1-9]\d*\.\d*|0\.\d*[1-9]\d*|0?\.0+|0)$/;
        return reg.test(str);
    },
    isFloat_length: function (str) {
        if (str == '') return true;
        let reg = /(^\d{1,8}(\.\d{1,2})?$)/;
        return reg.test(str);
    },
    isMoneyFloat: function (str) {
        if (str == '') return true;
        let reg = /(^\d{1,8}(\.\d{0,4})?$)/;
        return reg.test(str);
    },
    isMoneyFiveFloat: function (str) {
        if (str == '') return true;
        let reg = /(^\d{1,8}(\.\d{1,5})?$)/;
        return reg.test(str);
    },

    /**
     * 判断输入数值是否正确
     * @param callbackshowmsg   回调提示语
     * @param rule
     * @param value
     * @param callbackPreparePlanEdit
     */
    checkNumerical(callbackshowmsg, rule, value, callback) {
        if (Common.isMoneyFloat(value) && value.length > 0) {
            callback();
        } else {
            if (value != null && value.length > 1 && value.substring(value.length - 1, value.length) == ".") {
                callback();
            } else {
                if (value != null && value.length > 1) {
                    callback(callbackshowmsg);
                }
            }
        }
    },
    /**
     * 判断输入数值是否正确
     * @param callbackshowmsg   回调提示语
     * @param rule
     * @param value
     * @param callbackPreparePlanEdit
     */
    checkTwoInput(callbackshowmsg, rule, value, callback) {
        if (value != null) {
            let startvalue = value.startvalue;
            let endvalue = value.endvalue;
            let errormsg = "";
            if (value.datatype != null && value.datatype == "float") {
                if (Common.isMoneyFloat(startvalue) && startvalue.length > 0) {
                } else if (startvalue != null && startvalue.length > 0) {
                    errormsg = callbackshowmsg + "小于值格式不正确";
                }
                if (errormsg == "") {
                    if (Common.isMoneyFloat(endvalue) && endvalue.length > 0) {
                    } else if (endvalue != null && endvalue.length > 0) {
                        errormsg = callbackshowmsg + "大于值格式不正确";
                    }
                }
                if (errormsg == "" && startvalue != null && endvalue != null) {
                    if (parseFloat(startvalue) > parseFloat(endvalue)) {
                        errormsg = callbackshowmsg + "开始值不能大于结束值";
                    }
                }
            } else {
                if (Common.isIntNum(startvalue) && startvalue.length > 0) {
                } else if (startvalue != null && startvalue.length > 0) {
                    errormsg = callbackshowmsg + "小于值格式不正确";
                }
                if (errormsg == "") {
                    if (Common.isIntNum(endvalue) && endvalue.length > 0) {
                    } else if (endvalue != null && endvalue.length > 0) {
                        errormsg = callbackshowmsg + "大于值格式不正确";
                    }
                }
                if (errormsg == "" && startvalue != null && endvalue != null) {
                    if (parseFloat(startvalue) > parseFloat(endvalue)) {
                        errormsg = callbackshowmsg + "开始值不能大于结束值";
                    }
                }
            }
            if (errormsg.length > 0) {
                callback(errormsg);
            } else {
                callback();
            }
        } else {
            callback();
        }
    },


    /**
     * 数值-正负数格式判断 ( 保留 precision 位小数，默认2位)
     * @param str   要控制的值
     * @param precision 小数精度
     * @returns {*}
     */
    isPositiveAndNegativeMoneyFloatPrecision: function (str, precision) {
        if (str == '') return true;
        if (precision != null && precision != '') {
            let reg = /^[+-]?\d*\.?\d$/;
            return reg.test(str).toFixed(precision);
        } else {
            return this.isPositiveAndNegativeMoneyFloat(str);
        }
    },
    /**
     * 数值-正负数格式判断
     * @param str
     * @returns {boolean}
     */
    isPositiveAndNegativeMoneyFloat: function (str) {
        if (str == '') return true;
        let reg = /^[+-]?\d*\.?\d{0,4}$/;
        return reg.test(str);
    },

    /**
     * 数值保留4位小数
     * @param floatvalue
     * @returns {*}
     */
    formatFloat: function (floatvalue) {
        if (floatvalue != null) {
            floatvalue = floatvalue.toString();
            if (floatvalue.indexOf('.') > -1) {
                if (floatvalue.split('.')[1].length > 4) {
                    return parseFloat(floatvalue).toFixed(4);
                }
            }
        }
        return floatvalue;
    },
    /**
     * 检查是否为正整数包括0
     * @param strNumber
     * @returns {boolean}
     */
    isNum: function (strNumber) {
        let reg = /(^\d+$)/;
        return reg.test(strNumber);
    },
    /**
     * 检查是否为正整数包括0
     * @param strNumber
     * @returns {boolean}
     */
    isIntNum: function (strNumber) {
        let reg = /(^[-]?\d+$)/;
        return reg.test(strNumber);
    },
    /**
     * 检查是否为正整数不包括0
     * @param strNumber
     * @returns {boolean}
     */
    isNumThanZero: function (strNumber) {
        let reg = /^[1-9][0-9]*$/;
        return reg.test(strNumber);
    },

    /**
     * 验证由数字和26个英文字母组成的字符串：
     * @param str
     * @returns {boolean}
     */
    isNumOrEnglish: function (str) {
        let reg = /^[A-Za-z0-9]*$/;
        return reg.test(str);
    },

    /**
     * 金额数目千分位显示
     * @param num
     * @returns {string}
     */
    getThousandNum(num) {
        if (this.isStrEmpty(num)) {
            return undefined;
        }
        return num.toString().replace(/\d+/, function (n) { // 先提取整数部分
            return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) { // 对整数部分添加分隔符
                return $1 + ",";
            });
        });
    },
    /**
     * 千分位显示(保留指定小数位)
     * @param num
     * @param decimals  保留指定小数位
     * @returns {string}
     */
    getThousandNumRetainDecimals(num, decimals) {
        if (this.isStrEmpty(num)) {
            return undefined;
        }
        if (decimals != null && decimals >= 0) {
            return num.toFixed(decimals).toString().replace(/\d+/, function (n) { // 先提取整数部分
                return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) { // 对整数部分添加分隔符
                    return $1 + ",";
                });
            });
        } else {
            this.getThousandNum(num);
        }
    },
    /**
     * 字符串数值千分位显示(保留指定小数位)
     * @param num
     * @param decimals  保留指定小数位
     * @returns {string}
     */
    getThousandStringRetainDecimals(stringnum, decimals) {
        if (this.isStrEmpty(stringnum)) {
            return undefined;
        } else {
            return this.getThousandNumRetainDecimals(parseFloat(stringnum), decimals);
        }
    },
    /**
     * 字符串数值转换为以万为单位，并且千分位显示(保留指定小数位)
     * @param num
     * @param decimals  保留指定小数位
     * @param hideunit  是否显示单位(1：是；2：否)
     * @returns {string}
     */
    getThousandStringWanRetainDecimals(stringnum, decimals, hideunit) {
        if (this.isStrEmpty(stringnum)) {
            return undefined;
        } else {
            return this.getThousandNumRetainDecimals(parseFloat(stringnum) / 10000, decimals) + (hideunit == 1 ? '' : '万');
        }
    },
    /**
     * 字符串数值千分位显示(保留4位小数)
     * @param stringnum
     * @returns {string}
     */
    getFourDecimals(stringnum) {
        if (this.isStrEmpty(stringnum)) {
            return stringnum;
        } else {
            return this.getThousandNumRetainDecimals(parseFloat(stringnum), 4);
        }
    },
    /**
     * 字符串数值千分位显示(保留3位小数)
     * @param stringnum
     * @returns {string}
     */
    getThreeDecimals(stringnum) {
        if (this.isStrEmpty(stringnum)) {
            return stringnum;
        } else {
            return this.getThousandNumRetainDecimals(parseFloat(stringnum), 3);
        }
    },
    /**
     * 字符串数值千分位显示(保留2位小数)
     * @param stringnum
     * @returns {string}
     */
    getTwoDecimals(stringnum) {
        if (this.isStrEmpty(stringnum)) {
            return stringnum;
        } else {
            return this.getThousandNumRetainDecimals(parseFloat(stringnum), 2);
        }
    },
    /**
     * 是否为ip地址
     * @param e
     * @returns {*|boolean}
     */
    isValidIp: function (e) {
        return /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/.test(e)
    },
    IsURL: function (str_url) {
        var strRegex = '^((https|http|ftp|rtsp|mms)?://)'
            + '?(([0-9a-z_!~*().&=+$%-]+: )?[0-9a-z_!~*().&=+$%-]+@)?' //ftp的user@
            + '(([0-9]{1,3}.){3}[0-9]{1,3}' // IP形式的URL- 199.194.52.184
            + '|' // 允许IP和DOMAIN（域名）
            + '([0-9a-z_!~*()-]+.)*' // 域名- www.
            + '([0-9a-z][0-9a-z-]{0,61})?[0-9a-z].' // 二级域名
            + '[a-z]{2,6})' // first level domain- .com or .museum
            + '(:[0-9]{1,4})?' // 端口- :80
            + '((/?)|' // a slash isn't required if there is no file name
            + '(/[0-9a-z_!~*().;?:@&=+$,%#-]+)+/?)$';
        var re = new RegExp(strRegex);
        if (re.test(str_url)) {
            return (true);
        } else {
            return (false);
        }
    },
    /**
     * 转换为整数
     * @param str
     * @param defVal
     * @returns {*}
     */
    toInt(str, defVal) {
        if (typeof str === 'number') {
            return str;
        }
        const re = parseInt(str, 10);
        if (isNaN(re)) {
            return defVal;
        }
        return re;
    },
    /**
     * 保留 precision 位小数，默认2位
     * @param {number} num 要控制的值
     * @param {number} precision 小数精度
     * @param {number} defVal 转换失败时的默认值
     */
    toFixed(num, precision = 2, defVal) {
        let f = parseFloat(num);
        if (isNaN(f)) {
            return defVal;
        }
        return parseFloat(f.toFixed(precision));
    },
    toFloat(str, defVal) {
        if (typeof str === 'number') {
            return str;
        }
        const re = parseFloat(str);
        if (isNaN(re)) {
            return defVal;
        }
        return re;
    },
    /**
     * 限制整数
     * @param value 值
     * @param length 最大长度
     * @returns {string|*}
     */
    limitNumber(value, length) {
        if (!length) {
            length = 10;
        }
        let regex = /^\d{1,length}$/;
        if (!regex.test(value)) {
            return value.replace(/[^\d]/g, '') // 去掉非数字
                .substr(0, length); // 按指定长度截取
        }
        return value;
    },
    /**
     * 计算订单金额
     * @param {number} param0.middleunitprice 单价
     * @param {number} param0.amount 数量
     * @param {number} param0.middlediscount 折扣
     * @param {number} param0.middleconcessionsamount 优惠
     */
    sumMiddlelinkproductprice({middleunitprice, amount, middlediscount, middleconcessionsamount}) {
        const price = middleunitprice || 0;  // 单价
        const count = amount || 1;    // 数量
        const ze = (middlediscount || 100) / 100; // 折扣
        const y = middleconcessionsamount || 0;   // 优惠
        const result = count * price * ze - y;
        return result;
    },
    /**
     *  非空数值转换为字符串
     * @param str
     * @returns {*}
     */
    toStringByCommon(str) {
        if (this.isStrEmpty(str)) {
            return null;
        } else {
            return str.toString();
        }
    },
    toTrim(str) {
        if (this.isStrEmpty(str)) {
            return null;
        } else {
            return str.replace(/^\s+|\s+$/gm, '');
            ;
        }
    },

    /**************数值-结束***********************************-开始***************/


    compareDispatchItem: function (objone, objtwo) {
        let value1 = objone.itemorder != null ? objone.itemorder : 0;
        let value2 = objtwo.itemorder != null ? objtwo.itemorder : 0;
        if (parseInt(value1) < parseInt(value2)) {
            return -1;
        } else if (parseInt(value1) > parseInt(value2)) {
            return 1;
        } else {
            return 0;
        }
    },


    /**
     * 检测浏览器
     */
    bowerCheck() {
        // let userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
        // let isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
        // let isIE = userAgent.indexOf("compatible.less.less") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
        // let isEdge = userAgent.indexOf("Windows NT 6.1; Trident/7.0;") > -1 && !isIE; //判断是否IE的Edge浏览器
        // let isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
        // let isSafari = userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
        // let isChrome = userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器
    },

    /**
     * 生成一个key作为唯一标识符
     */
    getKey() {
        return new Date().getTime() + this.getRadom(3);
    },

    /**
     * 生产随机数
     * @param {number} len 随机数长度
     */
    getRadom(len) {
        const l = Math.pow(10, len);
        const re = Math.random() * l;
        return (re + "").substr(0, len);
    },

    /**
     * 设置网页标题
     * @param {string} title 标题
     */
    setWebTitle(title) {
        document.title = title;
    },

    /**
     * 返回事件
     */
    goBack(props) {
        props.history.go(-1)
    },

    /**
     *把数组转字符串
     * @param array 数组
     * @returns str  格式[value,value.....]
     */
    arrayToStr(array) {
        let str = ""
        if (Common.isNotEmptyArray(array)) {
            for (let index in array) {
                if (index != 0) {
                    str = str.concat(",");
                }
                str = str.concat(array[index]);
            }
        }
        return str;
    },

    /**
     * 数据字典列表 转换为下拉框的options数组
     * @param diclist
     * @returns {[]}
     */
    dicListToOptions(diclist) {
        let list = [];
        if (Common.isNotEmptyArray(diclist)) {
            list = diclist.map(item => {
                return {
                    label: item.dicdataname,
                    value: item.dicdatavalue,
                }
            })
        }
        return list;
    },

    /**
     * 判断对象是否整数
     * @param obj
     * @returns {boolean}
     */
    isInteger(obj) {
        const regex = /^-?\d+$/g
        return regex.test(obj)
    },

    /**
     * 判断是否是整数
     * 是 返回  原值
     * 否 返回  四舍五入 保留精度
     * @param num
     * @param precision 精度
     */
    numberToFixed(num, precision) {
        let _num = Tool.clone(num);
        if (!this.isInteger(_num)) {
            if (!isNaN(_num)) {
                _num = Number(_num).toFixed(precision);
            }
        }
        return _num;
    },


    getSaleProjectTypes() {
        return Common.ProjectType.Build.value + "," +
            Common.ProjectType.Normal.value + "," +
            Common.ProjectType.Exhibitor.value + "," +
            Common.ProjectType.Research.value + "," +
            Common.ProjectType.WholeLine.value + "," +
            Common.ProjectType.Multimachine.value + "," +
            Common.ProjectType.StandAlone.value;
    },

    getOtherProjectTypes() {
        return Common.ProjectType.FreeAfterSale.value + "," +
            Common.ProjectType.ChargeAfterSale.value + "," +
            Common.ProjectType.Supplement.value + "," +
            Common.ProjectType.AgreementContract.value + ",";
    },

    isOtherProjectTypes(projecttype) {
        const istrue = projecttype === Common.ProjectType.FreeAfterSale.value ||
            projecttype === Common.ProjectType.ChargeAfterSale.value ||
            projecttype === Common.ProjectType.Supplement.value ||
            projecttype === Common.ProjectType.AgreementContract.value;
        return istrue
    },

    isSaleProjectTypes(projecttype) {
        const istrue = projecttype === Common.ProjectType.Build.value ||
            projecttype === Common.ProjectType.Normal.value ||
            projecttype === Common.ProjectType.Exhibitor.value ||
            projecttype === Common.ProjectType.Research.value ||
            projecttype === Common.ProjectType.WholeLine.value ||
            projecttype === Common.ProjectType.Multimachine.value ||
            projecttype === Common.ProjectType.StandAlone.value;
        return istrue
    },

    documentsize: 209715200,//附件大小
    documentsignage: '附件最大200M，支持格式：PDF、Word、Excel、JPG、PNG、BMP、GIF、RAR、ZIP',//附件文案
    documentshowmsg: '附件过大，请修改附件重新上传',//附件提示语
    documentuploadfile: '/api-base' + '/FileUpDown/uploadfile',//文件上传后台接口
    downloadFile: '/api-base' + '/FileDownload/downloadFile',//根据文件路径下载文件
    // downloadFileByfilename: '/FileDownload/downloadFileByfilename',//根据文件路径与文件名，下载文件
    // download:'/FileDownload/download',//根据文件路径下载文件
    // downloadByfilename: '/FileDownload/downloadByfilename',//根据文件路径与文件名，下载文件

    pathname: '/app/pages/UnAuth',//无权限时，跳转的页面/app/systemmanage/PersonalSetting
    // dashboardpathname: '/app/homepage/HomepageIndex',//跳转首页

    salt: '_salt_',//加盐加密
    devdebug: baseurl.indexOf("localhost") != -1 ? true : false,//是否开发调试模式
    tryout: baseurl.indexOf("localhost") != -1 || baseurl.indexOf("39.107.154.200") != -1 ? true : false,//是否显示试用按钮

    ReportTypes: {
        FinancePlan: {
            reporttype: '10000',
            title: "财务预算导入导出"
        },
        DownProjectTeam: {
            reporttype: '20000',
            title: "项目现场人员导入导出"
        },
        DownQuote: {
            reporttype: '30000',
            title: "报价导出"
        },
        DownQuoteBaseSetting: {
            reporttype: '40000',
            title: "报价基础数据导出"
        },
        ImportQuoteBaseSetting: {
            reporttype: '60000',
            title: "报价基础数据导入"
        },
        DownQuoteBom: {
            reporttype: '70000',
            title: "报价Bom导出"
        },
        ImportQuoteBom: {
            reporttype: '80000',
            title: "报价Bom导入"
        },
        ImportDesignBom: {
            reporttype: '90000',
            title: "工程料单导入"
        },
        ImportSendsboxBom: {
            reporttype: '100000',
            title: "装箱规划发货清单导入"
        },
        ImportBomImpDetail: {
            reporttype: 110000,
            title: "总清单批次导入_自制外购"
        },
        Sendsbox: {
            reporttype: 120000,
            title: "装箱清单导入导出"
        },
        DownAccountingReport: {
            reporttype: 130000,
            title: "决算报告导出"
        },
        DownSaleWeek: {
            reporttype: 140000,
            title: "销售周报导出"
        },
        SendMessage: {
            reporttype: 150000,
            title: "发货信息表导入导出"
        },
        DownProWeeklyReport: {
            reporttype: 160000,
            title: "项目周报导出"
        },
        ImportBomImpDetail_Out: {
            reporttype: 170000,
            title: "总清单批次导入_外包"
        },
        ImportBulkBom: {
            reporttype: 180000,
            title: "散货清单导入"
        },
        DownFinalAccounting: {
            reporttype: 190000,
            title: "财务决算导出"
        },
        DownPlaninfo: {
            reporttype: 200000,
            title: "项目计划导出"
        },
        DownOpportunityList: {
            reporttype: 210000,
            title: "商机列表导出"
        },
        DownProWeeklyReportList: {
            reporttype: 220000,
            title: "项目周报列表导出"
        },
        DownProjectList: {
            reporttype: 230000,
            title: "项目列表导出"
        },
        DownNoArrivalItemList: {
            reporttype: 240000,
            title: "采购未到货项次导出"
        },
        DownArrivalItemList: {
            reporttype: 250000,
            title: "采购已到货项次导出"
        },
        DownSendboxBomView_send: {
            reporttype: 260000,
            title: "发货单发货清单导出"
        },
        DownProjectComplete: {
            reporttype: 270000,
            title: "项目竣工申请导出"
        },
        DownSendboxBomView_log: {
            reporttype: 280000,
            title: "物流发货清单导出"
        },
        DownSendboxBomView_plan: {
            reporttype: 290000,
            title: "发货安排发货清单导出"
        },
    },
    TimestampType: {
        LongTimestamp: {
            showmsg: "【格式：2020-04-20 10:14:32】",
            value: 1
        },
        YearMonthDay: {
            showmsg: "【格式：2020-04-20】",
            value: 2
        },
        MonthDay: {
            showmsg: "【格式：04-20】",
            value: 3
        },
        MonthDayTime: {
            showmsg: "【格式：04-20 10:14】",
            value: 4
        },
    },

    /**********  **********  ********** 乐惠枚举 开始**************  **********  **********/
    /**
     * 项目类型（ProjectType枚举）
     */
    ProjectType: {
        Normal: {
            showmsg: "一般合同",
            value: "NB18",
        },
        Build: {
            showmsg: "建造合同",
            value: "NB17",
        },
        Exhibitor: {
            showmsg: "参展项目",
            value: "NB12",
        },
        Research: {
            showmsg: "研发项目",
            value: "NB08",
        },
        WholeLine: {
            showmsg: "包装车间整线",
            value: "NJ01",
        },
        Multimachine: {
            showmsg: "包装车间多机",
            value: "NJ02",
        },
        StandAlone: {
            showmsg: "包装车间单机",
            value: "NJ03",
        },
        FreeAfterSale: {
            showmsg: "免费售后项目",
            value: "NB19",
        },
        ChargeAfterSale: {
            showmsg: "收费售后项目",
            value: "NB07",
        },
        Supplement: {
            showmsg: "增补合同",
            value: "NB21",
        },
        AgreementContract: {
            showmsg: "协议合同",
            value: "NB20",
        }
    },


    IndustryType: {
        Craft: {
            showmsg: "精酿",
            value: "1",
        },
        Beer: {
            showmsg: "啤酒",
            value: "2",
        },
        FoodAndMilk: {
            showmsg: "食品饮料乳品",
            value: "3",
        },
        BiologyAndMedicineAndChemical: {
            showmsg: "生物化工医药",
            value: "4",
        },
        Exhibition: {
            showmsg: "展会",
            value: "5",
        },
        Research: {
            showmsg: "研发",
            value: "6",
        },
        PreTender: {
            showmsg: "预投",
            value: "7",
        },
        Wliquor: {
            showmsg: "白酒",
            value: "9",
        },
        Rest: {
            showmsg: "其他",
            value: "8",
        },
    },

    DeviceType: {
        Pot: {
            showmsg: "罐类",
            value: "1",
        },
        System: {
            showmsg: "系统类",
            value: "2",
        },
        Design: {
            showmsg: "设计服务",
            value: "3",
        },
        Install: {
            showmsg: "安装服务",
            value: "4",
        },
        AfterSale: {
            showmsg: "售后服务",
            value: "5",
        }
    },

    /**
     * 商机状态（BusinessStatus枚举）
     */
    BusinessStatus: {
        Cancel: {
            showmsg: "作废",
            value: "-20",
        },
        Return: {
            showmsg: "退回",
            value: "-10",
        },
        New: {
            showmsg: "新建",
            value: "0",
        },
        WaitAudit: {
            showmsg: "待审核",
            value: "10",
        },
        Doing: {
            showmsg: "进行中",
            value: "20",
        },
        Win: {
            showmsg: "赢单",
            value: "30",
        },
        Lose: {
            showmsg: "输单",
            value: "40",
        },
        Invalid: {
            showmsg: "无效",
            value: "50",
        },
        Stop: {
            showmsg: "暂停",
            value: "60",
        },
        WaitStartUpAudit: {
            showmsg: "启动申请审核",
            value: "70",
        },
        WaitStopAudit: {
            showmsg: "暂停申请审核",
            value: "80",
        },
    },

    /**
     *  通用节点来源类型(后台枚举类:NodeFromType)
     */
    NodeFromType: {
        Business: {
            showmsg: "商机阶段",
            value: "1",
        }
    },

    /**
     *  报价状态(后台枚举类:BjProjectState)
     */
    BjProjectState: {
        Return: {
            showmsg: "退回",
            value: "-10",
        },
        Create: {
            showmsg: "待报价",
            value: "0",
        },
        Check: {
            showmsg: "待审核",
            value: "10",
        },
        Doing: {
            showmsg: "进行中",
            value: "15",
        },
        Finish: {
            showmsg: "已完成",
            value: "20",
        },
    },

    /**
     *  移交状态(后台枚举类:HandoverStatus)
     */
    HandoverStatus: {
        Return: {
            showmsg: "退回",
            value: "-10",
        },
        WaitHandover: {
            showmsg: "待移交",
            value: "0",
        },
        WaitReceipt: {
            showmsg: "待接收",
            value: "10",
        },
        Finish: {
            showmsg: "已完成",
            value: "20",
        },
    },

    /**
     *  考察状态(后台枚举类:InspectStatus)
     */
    InspectStatus: {
        WaitInspect: {
            showmsg: "待考察",
            value: "0",
        },
        Inspected: {
            showmsg: "已考察",
            value: "10",
        }
    },

    /**
     *  审批单据-来源类型(后台枚举类:ApprovalFromType)
     */
    ApprovalFromType: {
        Opportunity: {
            showmsg: "商机登记",
            value: 10
        },
        OfferManage: {
            showmsg: "报价管理",
            value: 20
        },
        StartOrStop: {
            showmsg: "项目启动或暂停",
            value: 110
        },
        BudgetChange: {
            showmsg: "预算变更",
            value: 120
        },
        ScopeChange: {
            showmsg: "范围变更",
            value: 150
        }
    },

    /**
     *  报价物料类型(后台枚举类:BjMaterialType)
     */
    BjMaterialType: {
        Finish: {
            showmsg: "成品",
            value: 1
        },
        Half: {
            showmsg: "半成品",
            value: 2
        },
        Original: {
            showmsg: "原材料",
            value: 3
        },
    },

    /**
     * 数据状态(后台枚举类:DataState)
     */
    DataState: {
        FREEZE: {
            showmsg: "冻结",
            value: -2
        },
        DELETED: {
            showmsg: "已删除",
            value: -1
        },
        FORBIDDEN: {
            showmsg: "禁用",
            value: 0
        },
        NORMAL: {
            showmsg: "正常",
            value: 1
        },
    },

    /**
     * 文件配置业务模块(后台枚举类:BizModule)
     */
    BizModule: {
        Module0: {
            value: 0,
            showmsg: "无关联",
            bizTableName: "",
            bizTargetTableName: "",
            isLink: true
        },
        Module1: {
            value: 1,
            showmsg: "项目移交",
            bizTableName: "pm_handover",
            bizTargetTableName: "pm_project",
            isLink: true
        },
        Module2: {
            value: 2,
            showmsg: "合同管理",
            bizTableName: "pm_contract",
            bizTargetTableName: "pm_contract",
            isLink: true
        },
        Module3: {
            value: 3,
            showmsg: "项目竣工",
            bizTableName: "pm_completed",
            bizTargetTableName: "pm_project",
            isLink: true
        },
        Module4: {
            value: 4,
            showmsg: "问题管理",
            bizTableName: "pbm_problem",
            bizTargetTableName: "pbm_problem",
            isLink: true
        },
        Module5: {
            value: 5,
            showmsg: "商机跟进",
            bizTableName: "pm_project",
            bizTargetTableName: "pm_project",
            isLink: true
        },
        Module6: {
            value: 6,
            showmsg: "移交考察",
            bizTableName: "pm_handover",
            bizTargetTableName: "pm_handover",
            isLink: true
        },
        Module7: {
            value: 7,
            showmsg: "发货计划",
            bizTableName: "send_sendplanitem",
            bizTargetTableName: "send_sendplan",
            isLink: true
        },
    },

    OpportunityNode: {
        CustormerNeed: {
            value: 10,
            showmsg: "客户需求"
        },
        Enquiry: {
            value: 20,
            showmsg: "询价"
        },
        Bid: {
            value: 30,
            showmsg: "投标"
        },
        Result: {
            value: 40,
            showmsg: "结果"
        },
    },

    /**
     * 工单类型 WorkTpe
     */
    WorkType: {
        Dsgin: {
            value: 10,
            showmsg: "设计工单"
        },
        Buy: {
            value: 20,
            showmsg: "采购工单"
        },

        Produce: {
            value: 30,
            showmsg: "生产工单"
        },
        Send: {
            value: 40,
            showmsg: "物流工单"
        },
        Constru: {
            value: 50,
            showmsg: "施工工单"
        },
    },


    /**
     * 工单状态 WorkorderState
     */
    WorkorderState: {
        NotStarted: {
            value: 0,
            showmsg: "未开始"
        },
        Assign: {
            value: 10,
            showmsg: "待分配"
        },
        Feedback: {
            value: 20,
            showmsg: "待反馈"
        },
        Doing: {
            value: 30,
            showmsg: "进行中"
        },
        Finish: {
            value: 40,
            showmsg: "已完成"
        }
    },


    /**
     * 工单任务枚举 WorkPlanState
     */
    WorkPlanState: {
        Doing: {
            value: 0,
            showmsg: "进行中"
        },
        Finish: {
            value: 1,
            showmsg: "已完成"
        }
    },
    /**
     * 工程料单 物料类型枚举 MaterialType
     */
    MaterialType: {
        HomemadeParts: {
            value: 10,
            showmsg: "自制件"
        },
        PurchasedParts: {
            value: 20,
            showmsg: "外购件"
        },
        OutsourceParts: {
            value: 30,
            showmsg: "外包件"
        }
    },
    /**
     * 项目计划状态枚举 PlanInfoState
     */
    PlanInfoState: {
        Create: {
            value: 10,
            showmsg: "创建中"
        },
        NoPass: {
            value: 15,
            showmsg: "退回"
        },
        Review: {
            value: 20,
            showmsg: "待审核"
        },
        Doing: {
            value: 30,
            showmsg: "执行中"
        },
        Finish: {
            value: 40,
            showmsg: "已完成"
        },
    },
    /**
     * 项目子计划状态 PlanState
     */
    PlanState: {
        Nostart: {
            value: 10,
            showmsg: "未开始"
        },
        Doing: {
            value: 20,
            showmsg: "进行中"
        },
        Finish: {
            value: 30,
            showmsg: "已完成"
        },
        Delay: {
            value: 40,
            showmsg: "延误"
        },
    },
    /**
     * 项目子计划变更状态
     */
    PlanChangeState: {
        Add: {
            value: 10,
            showmsg: "新增"
        },
        Change: {
            value: 20,
            showmsg: "变更"
        },
        Invalid: {
            value: 30,
            showmsg: "作废"
        }
    },


    MemberFromType: {
        Opp: {
            value: 10,
            showmsg: "商机"
        },
        Project: {
            value: 20,
            showmsg: "项目"
        },

    },
    /**
     * 周报状态
     */
    Weeklystate: {
        Save: {
            value: 0,
            showmsg: "保存"
        },
        Subimt: {
            value: 1,
            showmsg: "提交"
        }
    },

    /**
     * 木箱状态
     */
    InBoxState: {
        NotVerify: {
            value: 0,
            showmsg: "未确认"
        },
        Verify: {
            value: 10,
            showsmg: "已确认"
        },
        InBox: {
            value: 20,
            showmsg: "已装箱"
        },
        Sended: {
            value: 30,
            showmsg: "已发货"
        },
        Signed: {
            value: 40,
            showmsg: "已签收"
        }
    },
    /**
     * 装箱规划状态 BoxPlanState
     */
    BoxPlanState: {
        Doing: {
            value: 0,
            showmsg: "进行中"
        },
        Finish: {
            value: 10,
            showmsg: "已完成"
        },
    },

    /**
     * 集装箱状态 ContainerState
     */
    ContainerState: {
        NotSend: {
            value: 0,
            showmsg: "未发货"
        },
        Sended: {
            value: 10,
            showmsg: "已发货"
        },
    },

    /**
     * 发货单状态 SendState
     */
    SendState: {
        NotSign: {
            value: 10,
            showmsg: "未签收"
        },
        OnGing: {
            value: 15,
            showmsg: "签收中"
        },
        Signed: {
            value: 20,
            showmsg: "已签收"
        }
    },


    /**********  **********  ********** 乐惠枚举 结束**************  **********  **********/

    /**********  **********  ********** 结束****后台枚举类开始 **********  **********  **********/
    /**
     * OrgState(后台枚举类:TableThreeState)
     */
    OrgState: {
        SUMBIT: {
            showmsg: "启用",
            value: 20,
        },
        DISABLED: {
            showmsg: "停用",
            value: -1,
        },
        DELETED: {
            showmsg: "作废",
            value: -2,
        },
    },
    SettleType: {
        AMONTHPAY: {
            showmsg: "月结",
            value: 10,
        },
        BGOODSPAY: {
            showmsg: "货到付款",
            value: 20,
        },
        PAYGOODS: {
            showmsg: "款到发货",
            value: 30,
        },
    },
    /**
     * 纳税人类型(后台枚举类:TaxpayerType)
     */
    TaxpayerType: {
        General: {
            showmsg: "一般纳税人",
            value: 10,
        },
        Small: {
            showmsg: "小规模纳税人",
            value: 20,
        },
    },
    /**
     * 返回结果状态码(后台枚举类:StateCode)
     */
    StateCode: {
        UnKnow: {
            showmsg: "未知错误",
            value: -1
        },
        Error: {
            showmsg: "失败",
            value: 0
        },
        Success: {
            showmsg: "成功",
            value: 1
        },
        NotShow_Error: {
            showmsg: "不可展示错误",
            value: 3
        }
    },


    /**
     * 布尔类型的枚举类 (后台枚举类:TrueOrFalse)
     */
    TrueOrFalse: {
        True: {
            showmsg: "是",
            value: 1
        },
        False: {
            showmsg: "否",
            value: 0
        }
    },
    /**
     * 操作类型
     */
    operationtype: {
        save: {
            showmsg: "新增",
            value: 1,
            pagetype: "add",
        },
        update: {
            showmsg: "修改",
            value: 2,
            pagetype: "edit",
        },
        detail: {
            showmsg: "详情",
            value: 3,
            pagetype: "view",
        },
        delete: {
            showmsg: "删除",
            value: 4,
            pagetype: "delete",
        },
        audit: {
            showmsg: "审批",
            value: 4,
            pagetype: "audit",
        }
    },
    /**
     * 任务状态枚举
     */
    TaskState: {
        Success: {
            showmsg: "已完成",
            value: 1
        },
        Fail: {
            showmsg: "任务失败",
            value: -1
        },
        // SonFail: {
        //     showmsg: "子任务失败",
        //     value: -2
        // },
        UnSubmit: {
            showmsg: "执行中",
            value: 0
        },
        // WaitContinue: {
        //     showmsg: "待执行子任务",
        //     value: 2
        // },
    },
    /**
     * 业务员枚举
     */
    DutyType: {
        Salesman: {
            showmsg: "业务员",
            value: 10
        },
    },
    /**
     * 数据字典树类型 (后台枚举类:TableTwoDisabledState)
     */
    TableTwoDisabledState: {
        Sumbit: {
            showmsg: "启用",
            value: 20
        },
        Disabled: {
            showmsg: "停用",
            value: -1
        }
    },
    /**
     * 数据字典树类型 (后台枚举类:TableFourState)
     */
    TableFourState: {
        Sumbit: {
            showmsg: "提交",
            value: 20
        },
        Normal: {
            showmsg: "保存",
            value: 10
        },
        Disabled: {
            showmsg: "停用",
            value: -1
        },
        Deleted: {
            showmsg: "作废",
            value: -2
        }
    },
    /**
     *  (后台枚举类:TableThreeDeleteState)
     */
    TableThreeDeleteState: {
        SAVE: {
            showmsg: "保存",
            value: 10
        },
        SUBMIT: {
            showmsg: "提交",
            value: 20
        },
        DELETE: {
            showmsg: "作废",
            value: -2
        }
    },
    /**
     *  (后台枚举类:UserState)
     */
    UserState: {
        Sumbit: {
            showmsg: "启用",
            value: 20
        },
        Disable: {
            showmsg: "停用",
            value: -1
        },
        Delete: {
            showmsg: "作废",
            value: -2
        }
    },

    ProductGradeState: {
        Enable: {
            showmsg: "启用",
            value: 10,
        },
        Disable: {
            showmsg: "停用",
            value: -1,
        },
    },

    // //附件分类
    // fileCatalog:{
    //     //预约单
    //     reservation : 0,
    //     //健康告知书
    //     health : 1,
    //     //用车预定
    //     car : 2,
    //     //照会资料
    //     diplomatic : 3,
    //     //理赔资料
    //     claim : 4,
    //     //银行开户
    //     bank : 5,
    //     //其他
    //     others : 6
    // },
    /**
     * 异常状态 (后台枚举类:AbnormalState)
     */
    AbnormalState: {
        Normal: {
            showmsg: "正常",
            value: 10
        },
        Change: {
            showmsg: "变更",
            value: 20
        },
        ChangeDone: {
            showmsg: "变更已处理",
            value: 25
        },
        Rework: {
            showmsg: "返工",
            value: 30
        },
        ReworkDone: {
            showmsg: "返工已处理",
            value: 35
        },
        Swap: {
            showmsg: "换标",
            value: 40
        },
        SwapDone: {
            showmsg: "换标已处理",
            value: 45
        }
    },
    /**********  **********  公共枚举类结束****组织用户角色枚举类开始  **********  **********/
    /**
     * RoleType(后台枚举类:RoleType)
     */
    RoleType: {
        BZC: {
            showmsg: "本企业",
            value: 0,
        },
        HTQ: {
            showmsg: "供应链端",
            value: 10,
        },
        Operate: {
            showmsg: "运营端",
            value: 20,
        },
        Partner: {
            showmsg: "供应商端",
            value: 30,
        },
    },
    /**
     * orgtype(后台枚举类:OrgType)
     */
    OrgType: {
        BZC: {
            showmsg: "运营企业",
            value: 0,
        },
        LEHUI: {
            showmsg: "乐惠",
            value: 10,
        },
        Partner: {
            showmsg: "供应商",
            value: 20,
        },
        Forwarder: {
            showmsg: "货运代理",
            value: 30,
        },
        Operate: {
            showmsg: "运营组",
            value: 40,
        },
    },

    /**
     * 数据字典树类型 (后台枚举类:DictionaryDictype)
     */
    DictionaryDictype: {
        Editable: {
            showmsg: "可编辑",
            value: 1
        },
        UnEditable: {
            showmsg: "不可编辑",
            value: 2
        },
        Customize: {
            showmsg: "自定义",
            value: 3
        },
        JustUpdate: {
            showmsg: "只能修改",
            value: 4
        }
    },

    /**
     * 程序端类型 (后台枚举类:MenuType)
     */
    MenuType: {
        Operation: {
            showmsg: "运营端",
            value: 1
        },
        SupplyChain: {
            showmsg: "供应链端",
            value: 2
        },
        Supplier: {
            showmsg: "供应商端",
            value: 3
        }
    },

    /**********  ********** 组织用户角色枚举类结束****节点状态-开始**********  **********/
    /**
     *  项目节点表.节点状态 (后台枚举类:NodeState)
     */
    NodeState: {
        Wait: {
            showmsg: "待处理",
            value: 10
        },
        Process: {
            showmsg: "处理中",
            value: 20
        },
        Error: {
            showmsg: "终止",
            value: 30
        },
        Finish: {
            showmsg: "完成",
            value: 40
        },
    },

    /**********  ********** 节点状态-结束****物料开始 ********** **********/
    /**
     * 物料类型 (后台枚举类:MaterialType)
     */
    // MaterialType: {
    //     Product: {
    //         showmsg: "成品",
    //         value: 0
    //     },
    //     MainMaterial: {
    //         showmsg: "主材",
    //         value: 1
    //     },
    //     PackageMaterial: {
    //         showmsg: "包材",
    //         value: 2
    //     },
    //     FittingPackage: {
    //         showmsg: "半成品",
    //         value: 3
    //     },
    //     SourceMaterial: {
    //         showmsg: "辅材",
    //         value: 4
    //     },
    //     Consumables: {
    //         showmsg: "耗材",
    //         value: 5
    //     }
    // },
    /**
     * 物料生产属型 (后台枚举类:PackageitemType)
     */
    PackageitemType: {
        Standard: {
            showmsg: "标准箱",
            value: 10
        },
        Tailbox: {
            showmsg: "尾箱",
            value: 20
        },
        UnRule: {
            showmsg: "不定规则",
            value: 30
        }
    },
    /**
     * 物料生产属型 (后台枚举类:ProductionOrderType)
     */
    ProductionOrderType: {
        Produce: {
            showmsg: "生产单",
            value: 10
        },
        Process: {
            showmsg: "加工单",
            value: 20
        }
    },
    /**
     * 物料生产属型 (后台枚举类:ProduceType)
     */
    ProduceType: {
        SelfProduce: {
            showmsg: "自制",
            value: 0
        },
        OutSource: {
            showmsg: "委外",
            value: 1
        },
        BuyMaterial: {
            showmsg: "采购",
            value: 2
        },
        ProcessingOrder: {
            showmsg: "加工单",
            value: 3
        }
    },
    /**
     * 申请发货类型 (后台枚举类:DeliveryApplyType)
     */
    DeliveryApplyType: {
        Stock: {
            showmsg: "仓库发货",
            value: 10
        },
        Buy: {
            showmsg: "委外/采购直发",
            value: 20
        },
    },
    /**
     * 备货明细发货类型 (后台枚举类:ShipmentType)
     */
    ShipmentType: {
        UnKnow: {
            showmsg: "未指定",
            value: 1
        },
        Stock: {
            showmsg: "仓库发货",
            value: 10
        },
        Buy: {
            showmsg: "委外/采购直发",
            value: 20
        },
    },
    /**
     * 计费方式
     */
    BillMethod: {
        BillByVolume: {
            showmsg: "材积计费",
            value: 10
        },
        BillByWeight: {
            showmsg: "重量计费",
            value: 20
        },
    },
    /**
     * 申请发货状态 (后台枚举类:DeliveryApplyState)
     */
    DeliveryApplyState: {
        Delete: {
            showmsg: "作废",
            value: -2
        },
        ToBeSubmitted: {
            showmsg: "待提交",
            value: 10
        },
        Dealing: {
            showmsg: "待发货",
            value: 20
        },
        PartDeliery: {
            showmsg: "部分发货",
            value: 30
        },
        Finish: {
            showmsg: "已全部发货",
            value: 40
        },
        Stop: {
            showmsg: "终止",
            value: 50
        },
    },
    /**
     * 申请发货明细状态 (后台枚举类:DeliveryApplyItemState)
     */
    DeliveryApplyItemState: {
        Delete: {
            showmsg: "作废",
            value: -2
        },
        ToBeSubmitted: {
            showmsg: "待提交",
            value: 10
        },
        Dealing: {
            showmsg: "待发货",
            value: 20
        },
        PartDeliery: {
            showmsg: "部分发货",
            value: 30
        },
        Finish: {
            showmsg: "已全部发货",
            value: 40
        },
        Stop: {
            showmsg: "终止",
            value: 50
        },
    },

    /**********  ********** 物料结束****备货单开始 ********** **********/

    StockestimateitemState: {
        NORMAL: {
            showmsg: "保存",
            value: 10
        },
        SUBMIT: {
            showmsg: "提交",
            value: 20
        },
    },
    /**
     * 备货计划状态 (后台枚举类:ApplyState)
     */
    ApplyState: {
        Sumbit: {
            showmsg: "待提交",
            value: 10
        },
        FirstTrial: {
            showmsg: "待初审",
            value: 20
        },
        FinalJudgment: {
            showmsg: "待终审",
            value: 30
        },
        Doing: {
            showmsg: "进行中",
            value: 40
        },
        Finish: {
            showmsg: "已完成",
            value: 50
        }
    },
    /**
     * 备货单明细操作类型 (后台枚举类:)
     */
    ApplyItemOperation: {
        Produce: {
            showmsg: "生产",
            value: 10
        },
        Procure: {
            showmsg: "采购",
            value: 20
        },
        Outsourcing: {
            showmsg: "委外",
            value: 30
        },
        InStock: {
            showmsg: "入库",
            value: 40
        },
        Delivery: {
            showmsg: "发货",
            value: 45
        },
        OperationLog: {
            showmsg: "操作日志",
            value: 50
        },
        ChangeHistory: {
            showmsg: "变更记录",
            value: 60
        }
    },
    /**
     * LogrecordModel.fromtype:单据操作日志记录-触发类型
     */
    LogRecordFromType: {
        Apply: {
            showmsg: "备货单",
            value: 10
        },
        BuyMaterial: {
            showmsg: "采购单",
            value: 20
        },
        Delivery: {
            showmsg: "发货单",
            value: 30
        },
        Inventory: {
            showmsg: "盘点",
            value: 40
        },
        Transfer: {
            showmsg: "移库",
            value: 41
        },
        OtherOutBound: {
            showmsg: "其他出库",
            value: 42
        },
        OtherInBound: {
            showmsg: "其他入库",
            value: 43
        },
        BuyMaterialArrive: {
            showmsg: "采购到货单",
            value: 50
        },
        GoodsReturn: {
            showmsg: "退货单",
            value: 60
        },
        ProduceOrder: {
            showmsg: "生产订单",
            value: 70
        },
        PriceChange: {
            showmsg: "价格调整单",
            value: 80
        },
        BudgetChange: {
            showmsg: "预算变更",
            value: 160
        },
        ScopeChange: {
            showmsg: "范围变更",
            value: 190
        }
    },

    /**********  ********** 备货单结束****MRP运算开始 ********** **********/
    /**
     * MrpModel.datastate  MRP单状态 (后台枚举类:MRPState)
     */
    MRPState: {
        MRP: {
            showmsg: "待运算",
            value: 10
        },
        Finish: {
            showmsg: "完成",
            value: 20
        }
    },
    /**
     * MrpModel.datastate  MRP单状态 (后台枚举类:MRPState)
     */
    MRPStockFromType: {
        Stock: {
            showmsg: "仓库库存",
            value: 10
        },
        OnWayStock: {
            showmsg: "在途库存",
            value: 20
        }
    },


    /**********  ********** MRP运算结束****生产单开始 ********** **********/
    /**
     * 排产单状态 (后台枚举类:DispatchState)
     */
    DispatchState: {
        ToBeSumbit: {
            showmsg: "待提交",
            value: 10
        },
        MRP: {
            showmsg: "待MRP运算",
            value: 20
        },
        Dispatching: {
            showmsg: "待排产",
            value: 30
        },
        Producing: {
            showmsg: "生产中",
            value: 40
        },
        Finish: {
            showmsg: "完成",
            value: 50
        },
        Close: {
            showmsg: "关闭",
            value: 60
        }
    },
    /**
     * 排产单来源类型 (后台枚举类:DispatchFromType)
     */
    DispatchFromType: {
        Entrust: {
            showmsg: "委外订单自制",
            value: 10
        },
        Apply: {
            showmsg: "备货计划",
            value: 20
        },
        MRP: {
            showmsg: "MRP",
            value: 30
        },
    },
    /**
     * 生产单状态 (后台枚举类:ProduceState)
     */
    ProduceState: {
        /*ToBeSumbit: {
            showmsg: "待提交",
            value: 10
        },
        MRP: {
            showmsg: "待MRP运算",
            value: 20
        },*/
        Picking: {
            showmsg: "待领料",
            value: 30
        },
        Producing: {
            showmsg: "生产中",
            value: 40
        },
        CheckUp: {
            showmsg: "待质检",
            value: 50
        },
        InStorage: {
            showmsg: "待入库",
            value: 60
        },
        Finish: {
            showmsg: "完成",
            value: 70
        }
    },
    /**
     * 生产单操作类型 (后台枚举类:)
     */
    ProduceOperation: {
        ChangePack: {
            showmsg: "改包装",
            value: 10
        },
        Bom: {
            showmsg: "维护BOM",
            value: 20
        },
        Picking: {
            showmsg: "领料",
            value: 30
        },
        SplitOrder: {
            showmsg: "拆单",
            value: 40
        },
        Weigh: {
            showmsg: "称重",
            value: 45
        },
        CheckUp: {
            showmsg: "质检",
            value: 50
        },
        InStorage: {
            showmsg: "入库",
            value: 60
        }
        , WorkTime: {
            showmsg: "工时记录",
            value: 70
        }
    },
    /**
     * 缺料单状态 (后台枚举类:LackState)
     */
    LackState: {
        Pending: {
            showmsg: "待处理",
            value: 10
        },
        Close: {
            showmsg: "关闭",
            value: 20
        },
        Finish: {
            showmsg: "完成",
            value: 30
        }
    },

    /**********  ********** 生产单结束****生产用料开始 ********** **********/
    /**
     * 生产用料单-领料类型 (后台枚举类:PackingType)
     *
     */
    PackingType: {
        Bom: {
            showmsg: "BOM",
            value: 10
        },
        Package: {
            showmsg: "包装属性",
            value: 20
        }
    },
    /**
     * 生产用料单状态 (后台枚举类:InOrOutMaterialState)
     *
     */
    InOrOutMaterialState: {
        ToBeSubmit: {
            showmsg: "待提交",
            value: 10
        },
        ToBeConfirmed: {
            showmsg: "待确认",
            value: 20
        },
        Finish: {
            showmsg: "已完成",
            value: 30
        }
    },
    /**
     * 生产用料单出入库类型 (后台枚举类:InOrOutMaterialType)
     *
     */
    InOrOutMaterialType: {
        Replenishment: {
            showmsg: "补领料",
            value: 10
        },
        Independence: {
            showmsg: "独立领料",
            value: 20
        },
        Order: {
            showmsg: "按单领料",
            value: 30
        },
        ReturnMaterial: {
            showmsg: "退料",
            value: 40
        }
    },


    /**********  ********** 生产用料结束****审批中心开始 ********** **********/
    /**
     *  审批单状态(后台枚举类:ApprovalState)
     */
    ApprovalState: {
        Cancel: {
            showmsg: "作废",
            value: -2
        },
        Pending: {
            showmsg: "待审批",
            value: 10
        },
        Pass: {
            showmsg: "通过",
            value: 20
        },
        Reject: {
            showmsg: "驳回",
            value: 30
        },
        Termination: {
            showmsg: "终止",
            value: 40
        },
    },


    /********** ********** 审批中心结束******开始 ********** **********/
    /**
     *  (后台枚举类:PayType)
     */
    PayType: {
        AmonthPay: {
            showmsg: "月结",
            value: 10
        },
        BgoodsPay: {
            showmsg: "货到付款",
            value: 20
        },
        PayGoods: {
            showmsg: "款到发货",
            value: 30
        }
    },
    /**
     *  采购单状态(后台枚举类:BuymaterialState)
     */
    BuymaterialState: {
        Delete: {
            showmsg: "作废",
            value: -2,
            group: 10
        },
        WaitSupplier: {
            showmsg: "待下单",
            value: 0,
            group: 20
        },
        WaitSubmit: {
            showmsg: "待提交",
            value: 10,
            group: 20
        },
        WaitCheck: {
            showmsg: "待审核",
            value: 20,
            group: 20
        },
        Refuse: {
            showmsg: "不通过",
            value: 30,
            group: 20
        },
        WaitMaterial: {
            showmsg: "待物料供应",
            value: 35,
            group: 30
        },
        GoodsApply: {
            showmsg: "到货申请",
            value: 40,
            group: 20
        },
        GoodsPartArrival: {
            showmsg: "部分到货",
            value: 50,
            group: 20
        },
        Complete: {
            showmsg: "完成",
            value: 60,
            group: 10
        },
        Closed: {
            showmsg: "关闭",
            value: 70,
            group: 10
        }
    },

    /**
     *  采购类型(后台枚举类:BuyType)
     */
    BuyType: {
        PlanBuy: {
            showmsg: "计划采购",
            value: 10
        },
        OutBuy: {
            showmsg: "委外采购",
            value: 20
        },
        OutBuyPlus: {
            showmsg: "委外独立采购",
            value: 30
        }
    },
    /**
     *  明细来源类型(后台枚举类:BuyFromItemType)
     */
    BuyFromItemType: {
        Needbuy: {
            showmsg: "请购单",
            value: 10
        },
        Apply: {
            showmsg: "备货计划",
            value: 20
        },
        Outbuy: {
            showmsg: "委外订单",
            value: 30
        }
    },
    /**
     *  明细来源类型(后台枚举类:NeedbuyFromItemType)
     */
    NeedbuyFromItemType: {
        Needbuy: {
            showmsg: "请购单",
            value: 10
        },
        Apply: {
            showmsg: "备货计划",
            value: 20
        },
        Mrp: {
            showmsg: "MRP",
            value: 30
        }
    },
    /**
     *  请购单来源类型(后台枚举类:NeedbuyFromType)
     */
    NeedbuyFromType: {
        Self: {
            showmsg: "自提",
            value: 10
        },
        Production: {
            showmsg: "生产单",
            value: 20
        },
        Apply: {
            showmsg: "备货单",
            value: 30
        },
        MRPOperation: {
            showmsg: "MRP运算",
            value: 40
        }
    },

    /**
     *  运输方式(后台枚举类:TransType)
     */
    TransType: {
        SeaTrans: {
            showmsg: "海运",
            value: 10
        },
        AirTrans: {
            showmsg: "空运",
            value: 20
        },
        LandTrans: {
            showmsg: "陆运",
            value: 30
        },
        ExpressTrans: {
            showmsg: "快递",
            value: 40
        }
    },

    /**
     *  出库单状态(后台枚举类:Outcomestate)
     */
    OutcomeState: {
        Delete: {
            showmsg: "作废",
            value: -2
        },
        WaitSubmit: {
            showmsg: "待提交",
            value: 10
        },
        WaitCheck: {
            showmsg: "待审核",
            value: 20
        },
        CheckNopass: {
            showmsg: "不通过",
            value: 30
        },
        WaitOut: {
            showmsg: "待出库",
            value: 40
        },
        Complete: {
            showmsg: "完成",
            value: 50
        }
    },

    /**
     * 箱子使用状态（后台枚举类：BoxUseState）
     */
    BoxUseState: {
        NotUse: {
            showmsg: "未使用",
            value: 10
        },
        Used: {
            showmsg: "已使用",
            value: 20
        }
    },

    /**
     * 请购单状态 (后台枚举类:NeedbuyState)
     */
    NeedbuyState: {
        Delete: {
            showmsg: "作废",
            value: -2
        },
        ToBeSubmitted: {
            showmsg: "待提交",
            value: 10
        },
        Dealing: {
            showmsg: "待处理",
            value: 20
        },
        Finish: {
            showmsg: "已完成",
            value: 30
        },
        Stop: {
            showmsg: "终止",
            value: 40
        }
    },
    /********** ********** 采购相关结束******FBA相关开始 ********** **********/

    /********** ********** FBA相关结束******开始 ********** **********/

    /********** ********** 财务模块开始*************** **********/
    /**
     * 账单状态 (后台枚举类:FeebillState)
     */
    FeebillState: {
        WaitConfirm: {
            showmsg: "待确认",
            value: 5
        },
        WaitPayment: {
            showmsg: "待结算",
            value: 10
        },
        AlreadyPayment: {
            showmsg: "已结算",
            value: 20
        }
    },

    /**
     * 来源类型(后台枚举类:FromType)
     */
    FromType: {
        BuymaterialPrepay: {
            showmsg: "采购预付款",
            value: 11,
            group: 10
        },
        BuymaterialPayGoods: {
            showmsg: "采购款到发货",
            value: 12,
            group: 10
        },
        BuymaterialGoodspay: {
            showmsg: "采购货到付款",
            value: 13,
            group: 10
        },
        BuymaterialMonthpay: {
            showmsg: "采购月结",
            value: 14,
            group: 10
        }, BuymaterialCheckPay: {
            showmsg: "预付款结转",
            value: 15,
            group: 10
        }, BuymaterialReturn: {
            showmsg: "预付款退款",
            value: 16,
            group: 10
        }, BuymaterialHedge: {
            showmsg: "采购对冲",
            value: 17,
            group: 10
        }, GoodsAgentGoodsPay: {
            showmsg: "货代货到付款",
            value: 23,
            group: 20
        }, GoodsAgenMonthpay: {
            showmsg: "货代月结",
            value: 24,
            group: 20
        }, GoodsAgenHedge: {
            showmsg: "货代对冲",
            value: 27,
        }, EntrustOtherPrePay: {
            showmsg: "委外预付",
            value: 31,
            group: 30
        }, EntrustOtherPayGoods: {
            showmsg: "委外款到发货",
            value: 32,
            group: 30
        },
        EntrustOtherGoodspay: {
            showmsg: "委外货到付款",
            value: 33,
            group: 30
        },
        EntrustOtherMonthpay: {
            showmsg: "委外月结",
            value: 34,
            group: 30
        }

    },

    /**
     * 费用类型(后台枚举类:FeeType)
     */
    FeeType: {
        Prepay: {
            showmsg: "预付款",
            value: 10
        },
        PayGoods: {
            showmsg: "款到发货",
            value: 20
        },
        GoodsPay: {
            showmsg: "货到付款",
            value: 30
        },

        MonthPay: {
            showmsg: "月结",
            value: 40
        },
        CheckPay: {
            showmsg: "结转",
            value: 50
        },
        Return: {
            showmsg: "退款",
            value: 60
        },
        Hedge: {
            showmsg: "对冲",
            value: 70
        },
    },

    /**
     * 结算单状态(后台枚举类:SettleState)
     */
    SettleState: {
        WaitSubmit: {
            showmsg: "待提交",
            value: 10
        },
        Refuse: {
            showmsg: "驳回",
            value: 13
        },
        WaitCheck: {
            showmsg: "待对账",
            value: 15
        },
        WaitReCheck: {
            showmsg: "待复核",
            value: 16
        },
        WaitPay: {
            showmsg: "待付款",
            value: 20,
            group: 10
        },
        FINISH: {
            showmsg: "已完成",
            value: 30,
            group: 10
        }
    },


    /**
     * 业务类型(后台枚举类:BusinessType)
     */
    BusinessType: {
        Supplier: {
            showmsg: "供应商",
            value: 10
        },
        GoodsAgent: {
            showmsg: "货代",
            value: 20
        },
        EntrustOther: {
            showmsg: "委外",
            value: 30
        }
    },

    /**
     * 收付款状态(后台枚举类:PayReceiveState)
     */
    PayReceiveState: {
        AlreadyPayReceive: {
            showmsg: "已付款",
            value: 40
        }
    },

    /**
     * 交易类型(后台枚举类:DealType)
     */
    DealType: {
        Receive: {
            showmsg: "收款",
            value: 10
        },
        Pay: {
            showmsg: "付款",
            value: 20
        },
    },

    /**
     * 交易类型(后台枚举类:DealType)
     */
    AdjustState: {
        WaitAdjust: {
            showmsg: "未结转",
            value: 10
        },
        AlreadyAdjust: {
            showmsg: "已结转",
            value: 20
        },
    },

    /********** ********** 财务模块结束**************** **********/


    /********** ********** 物流模块开始**************** **********/
    /**
     * 发货状态(后台枚举类:DeliveryState)
     */
    DeliveryState: {
        WaitSubmit: {
            showmsg: "待提交",
            value: 10
        },
        WaitNoticSupply: {
            showmsg: "待通知供应商",
            value: 11
        },
        WaitNoticForwarder: {
            showmsg: "待通知货代",
            value: 12
        },
        WaitNoticPickUp: {
            showmsg: "待通知提货",
            value: 13
        },
        WaitQualityTest: {
            showmsg: "待质检",
            value: 14
        },
        WaitDeliverySucess: {
            showmsg: "待发货完成",
            value: 15
        },
        WaitOut: {
            showmsg: "待出库",
            value: 20
        },
        WaitRegister: {
            showmsg: "待货代登记",
            value: 30
        },
        WaitSign: {
            showmsg: "待签收",
            value: 40
        },
        Complete: {
            showmsg: "完成",
            value: 50
        },
    },

    /**
     * 发货类型(后台枚举类:SendType)
     */
    SendType: {
        StockSend: {
            showmsg: "仓库发货",
            value: 10
        },
        BuyDirectSend: {
            showmsg: "采购直发",
            value: 20
        },
        OutsourceDirectSend: {
            showmsg: "委外直发",
            value: 30
        },
    },

    /**
     * 根据枚举类型获取showmsg
     * @param enumdata
     * @param value
     * @returns {*}
     */
    getEnumShowmsg(enumdata, value) {
        let showmsg;
        let arr = [];
        if (enumdata) {
            for (let i in enumdata) {
                let o = {};
                o.showmsg = enumdata[i].showmsg;
                o.value = enumdata[i].value;
                arr.push(o);
            }
            arr.some((item, index, arr) => {
                if (item.value == value) {
                    showmsg = item.showmsg;
                    return true;
                }
            })
        }
        return showmsg;
    },

    /********** ********** 物流模块结束**************** **********/


    /**
     * 亚马逊店铺是否合规 (后台枚举类:NormalState)
     */
    NormalState: {
        Compliance: {
            showmsg: "合规",
            value: 10
        },
        UnCompliance: {
            showmsg: "不合规",
            value: 20
        }
    },

    /**********  **********  ********** 仓库枚举类**开始 **********  **********  **********/
    WarehouseType: {
        FinishProduct: {
            showmsg: "成品仓",
            value: 10
        },
        RawMaterial: {
            showmsg: "原材料仓",
            value: 20
        },
        BadProduct: {
            showmsg: "不良品仓",
            value: 30
        },
        WaitDeliver: {
            showmsg: "待发货仓",
            value: 40
        },
        OutsideLine: {
            showmsg: "线边仓",
            value: 50
        },
        Virtual: {
            showmsg: "虚拟仓",
            value: 60
        },
        FBA: {
            showmsg: "FBA仓",
            value: 70
        }
    },
    StockState: {
        Cancel: {
            showmsg: "作废",
            value: -1
        },
        OutsideLine: {
            showmsg: "盘点锁定",
            value: 10
        },
        Virtual: {
            showmsg: "正常",
            value: 1
        }
    },
    InorOutState: {
        Cancel: {
            showmsg: "作废",
            value: -1
        },
        Appying: {
            showmsg: "申请中",
            value: 10
        },
        StoreFirstConfirm: {
            showmsg: "待仓库初次确认",
            value: 20
        },
        Checking: {
            showmsg: "待质检",
            value: 30
        },
        NowChecking: {
            showmsg: "质检中",
            value: 33
        },
        CheckExceptioning: {
            showmsg: "待质检异常处理",
            value: 35
        },
        Confirming: {
            showmsg: "待仓库确认",
            value: 40
        },
        Finish: {
            showmsg: "已完成",
            value: 50
        }
    },
    InventoryState: {
        Cancel: {
            showmsg: "作废",
            value: -1
        },
        WaitingSubmit: {
            showmsg: "待提交",
            value: 10
        },
        RollBack: {
            showmsg: "审核退回",
            value: 15
        },
        waitinginventory: {
            showmsg: "待盘点",
            value: 20
        },
        recordinventory: {
            showmsg: "盘点中",
            value: 30
        },
        diffinventory: {
            showmsg: "盘点差异分析",
            value: 40
        },
        dealwithinventory: {
            showmsg: "待盘点处理",
            value: 50
        },
        Finish: {
            showmsg: "已完成",
            value: 60
        }
    },
    TransferState: {
        Cancel: {
            showmsg: "作废",
            value: -1
        },
        New: {
            showmsg: "待提交",
            value: 0
        },
        WaitingSubmit: {
            showmsg: "待审核",
            value: 10
        },
        RollBack: {
            showmsg: "审核退回",
            value: 15
        },
        waitingTransfer: {
            showmsg: "进行中",
            value: 29
        },
        Finish: {
            showmsg: "调拔完成",
            value: 30
        }

    },

    TransferFromType: {
        OutSource: {
            showmsg: "委外直接调拨",
            value: 10
        },
        OutSourceBuy: {
            showmsg: "委外入库调拨",
            value: 20
        }
    },

    /**********  **********  ********** 仓库结束** **********  **********  **********/

    /**********  **********  **********质检结论 **********  **********  **********/
    QAResult: {
        AllInStock: {
            value: 10,
            buymsg: "让步签收-直接入库",
            producemsg: "全部签收",
        },
        ReduceOk: {
            value: 20,
            buymsg: "让步签收-价格调整",
            producemsg: "合格品签收"
        },
        RollBack: {
            value: 30,
            buymsg: "拒收退货",
            producemsg: "全部返工"
        },
    },
    DismantleType: {
        Original: {
            showmsg: "原单",
            value: 0
        },
        QADismantle: {
            showmsg: "质检拆单",
            value: 20
        },
        BadDismantle: {
            showmsg: "不良品拆单",
            value: 30
        }
    },
    InorOutStoreType: {
        BuyMaterial: {
            showmsg: "采购入库",
            value: 10,
            fromvalue: 10
        },
        BuyRollBack: {
            showmsg: "采购退货",
            value: 20,
            fromvalue: 10
        },
        TRANSferStoreIn: {
            showmsg: "调拨入库",
            value: 30,
            fromvalue: 30
        },
        TRANSferStoreOut: {
            showmsg: "调拨出库",
            value: 35,
            fromvalue: 30
        },
        InventoryOut: {
            showmsg: "盘点出库",
            value: 40,
            fromvalue: 40
        },
        InventoryIn: {
            showmsg: "盘点入库",
            value: 45,
            fromvalue: 40
        },
        ProduceInStore: {
            showmsg: "生产入库",
            value: 50,
            fromvalue: 50
        },
        ProduceOutStore: {
            showmsg: "生产入库扣减",
            value: 51,
            fromvalue: 50
        },
        TakeOut: {
            showmsg: "领料出库",
            value: 60,
            fromvalue: 60
        },
        TakeIn: {
            showmsg: "领料入库",
            value: 61,
            fromvalue: 60
        },
        PackingIn: {
            showmsg: "生产退料入库",
            value: 65,
            fromvalue: 65
        },
        PackingOut: {
            showmsg: "生产退料出库",
            value: 66,
            fromvalue: 65
        },
        OtherIn: {
            showmsg: "其它入库",
            value: 80,
            fromvalue: 70
        },
        OtherOut: {
            showmsg: "其它出库",
            value: 70,
            fromvalue: 70
        },
        SendOut: {
            showmsg: "销售出库",
            value: 90,
            fromvalue: 90
        },
        EntrustProduceIn: {
            showmsg: "委外成品入库",
            value: 100,
            fromvalue: 100
        },
        EntrustProduceSend: {
            showmsg: "委外成品直发",
            value: 110,
            fromvalue: 110
        },
        BuyProduceSend: {
            showmsg: "采购成品直发",
            value: 120,
            fromvalue: 120
        },
        BuyRawSend: {
            showmsg: "采购原材料直发",
            value: 130,
            fromvalue: 130
        },
        ChgProduceIn: {
            showmsg: "换标入库",
            value: 140,
            fromvalue: 140
        },
        ChgProduceOut: {
            showmsg: "换标出库",
            value: 150,
            fromvalue: 140
        },
    },
    /**********  **********  ********** 来源单类型 **********  **********  **********/
    BusinessPackageFromType: {
        Produce: {
            showmsg: "生产单",
            value: 10,
        },
        Process: {
            showmsg: "加工单",
            value: 20,
        },
        BuyOrder: {
            showmsg: "采购订单",
            value: 30,
        },
        OutSourcing: {
            showmsg: "委外订单",
            value: 40,
        },
    },
    /**
     * 业务单据成本来源类型（后台枚举类：CostFromType）
     */
    CostFromType: {
        Produce: {
            showmsg: "生产单",
            value: 10,
        },
        Delivery: {
            showmsg: "发货单",
            value: 20,
        },
    },

    /**********  **********  ********** 备货周期 **********  **********  **********/

    AsinCycleType: {
        Asin: {
            showmsg: "按ASIN",
            value: 10,
        },
        Productgrade: {
            showmsg: "按产品等级",
            value: 20,
        },
        Materialgrade: {
            showmsg: "按产品分类",
            value: 30,
        },
    },
    /***消息来源******/
    NoticeFromType: {
        downloadreport: {
            showmsg: "报表导出",
            value: "report",
        },
        sendmessage: {
            showmsg: "用户发送",
            value: "sendmessage",
        }
    },
    /***消息状态******/
    NoticeState: {
        delete: {
            showmsg: "删除",
            value: -1,
        },
        noread: {
            showmsg: "未读",
            value: 0,
        },
        haveread: {
            showmsg: "已读",
            value: 1,
        }
    },
    /**
     * 换标单状态
     */
    ChgProduceStatus: {
        Cancel: {
            showmsg: "作废",
            value: -20,
        },
        Fail: {
            showmsg: "不通过",
            value: -10,
        },
        WaitSubmit: {
            showmsg: "待提交",
            value: 0,
        },
        Confirming: {
            showmsg: "待确认",
            value: 10,
        },
        Ongoing: {
            showmsg: "进行中",
            value: 20,
        },
        Finished: {
            showmsg: "已完成",
            value: 30,
        }
    },
    /**
     * 换标变更类型
     */
    ChgProduceChangeType: {
        SplitPackage: {
            showmsg: "拆包",
            value: 1,
        },
        NotSplitPackage: {
            showmsg: "不拆包",
            value: 2,
        }
    },
    /**
     * 换标类型
     */
    ChgProduceType: {
        Warehouse: {
            showmsg: "库内换标",
            value: 1,
        },
        Overseas: {
            showmsg: "海外换标",
            value: 2,
        }
    },
    /**
     * 换标明细类型
     */
    ChgProduceFromType: {
        Manual: {
            showmsg: "手动新增",
            value: 0,
        },
        Apply: {
            showmsg: "备货计划",
            value: 1,
        }
    },
    /**
     * 换标明细类型
     */
    ChgProduceDetailType: {
        Before: {
            showmsg: "变更前",
            value: 1,
        },
        After: {
            showmsg: "变更后",
            value: 2,
        }
    },
    /**
     * 备货计划变更-变更状态
     */
    ChangeApplyState: {
        Cancel: {
            showmsg: "作废",
            value: -2,
        },
        SUMBIT: {
            showmsg: "待提交",
            value: 10,
        },
        Pending: {
            showmsg: "待审核",
            value: 20,
        },
        Reject: {
            showmsg: "不通过",
            value: 30,
        },
        Doing: {
            showmsg: "进行中",
            value: 40,
        },
        Finish: {
            showmsg: "已完成",
            value: 50,
        }
    },
    /**
     * 备货计划变更-变更类型
     */
    ChangeApplyType: {
        ChangePart: {
            showmsg: "变更部分FNSKU",
            value: 10,
        },
        ChangeAll: {
            showmsg: "变更全部FNSKU",
            value: 20,
        },
        ChangeProduceType: {
            showmsg: "变更生产方式",
            value: 30,
        }
    },
    CameraStatus: {
        Unbound: {
            showmsg: "未绑定",
            value: 0,
        },
        Bound: {
            showmsg: "已绑定",
            value: 10,
        }
    },
    CameraProjectStatus: {
        Running: {
            showmsg: "运行中",
            value: 0,
        },
        End: {
            showmsg: "已完结",
            value: 10,
        }
    },
    /**
     * 通用字段类型
     */
    OtherItemDatatype: {
        SpclTechnology: {
            showmsg: "移交的工艺特殊要求",
            value: 1,
        },
        SpclDesign: {
            showmsg: "移交的设计特殊要求",
            value: 2,
        },
        SendRequire: {
            showmsg: "移交的发货要求",
            value: 3,
        },
        SendSpot: {
            showmsg: "移交的发货现场",
            value: 4,
        },
        Qualification: {
            showmsg: "移交的制造方案",
            value: 5,
        },
        InspectBaseInfo: {
            showmsg: "移交考察的基本概况",
            value: 6,
        },
        TransportationCond: {
            showmsg: "移交考察的运输条件",
            value: 7,
        },
        HoistingCond: {
            showmsg: "移交考察的现场吊装条件",
            value: 8,
        },
        MaterialSupplyCond: {
            showmsg: "移交考察的当地材料供应条件",
            value: 9,
        },
        SubcontractingCond: {
            showmsg: "移交考察的当地施工分包",
            value: 10,
        },
        ProjectLogistics: {
            showmsg: "移交考察的项目后勤",
            value: 11,
        },
        SendMessage: {
            showmsg: "物流管理的发货信息表",
            value: 12,
        },
    },
    EquipmentType: {
        FermentationTank: {
            showmsg: "发酵罐",
            value: 1,
        },
        BrightBeerTank: {
            showmsg: "清酒罐",
            value: 2,
        },
        LauterTun: {
            showmsg: "过滤槽",
            value: 3,
        },
        OtherMush: {
            showmsg: "糖化其他",
            value: 4,
        }
    },
    /**
     * 项目状态（后台枚举ProjectStatus）
     */
    ProjectStatus: {
        NotStarted: {
            showmsg: "未开始",
            value: 10,
        },
        Started: {
            showmsg: "立项",
            value: 10,
        },
        Planning: {
            showmsg: "规划",
            value: 20,
        },
        Ongoing: {
            showmsg: "进行中",
            value: 30,
        },
        Inspection: {
            showmsg: "验收",
            value: 40,
        },
        Protection: {
            showmsg: "质保期",
            value: 50,
        },
        Close: {
            showmsg: "关闭",
            value: 60,
        }
    },
    ContractStatus: {
        Return: {
            showmsg: "退回",
            value: -10,
        },
        New: {
            showmsg: "待登记",
            value: 0,
        },
        WaitAudit: {
            showmsg: "待审核",
            value: 10,
        },
        WaitUpload: {
            showmsg: "待原件上传",
            value: 20,
        },
        WaitReceipt: {
            showmsg: "待收款",
            value: 30,
        },
        OnReceipting: {
            showmsg: "收款中",
            value: 40,
        },
        Finish: {
            showmsg: "已完成",
            value: 50,
        }
    },
    ContractType: {
        Normal: {
            showmsg: "一般合同",
            value: "1",
        },
        Supplement: {
            showmsg: "增补合同",
            value: "2",
        },
    },
    BudgetState: {
        Return: {
            showmsg: "退回",
            value: -10,
        },
        New: {
            showmsg: "待提交",
            value: 0,
        },
        WaitPMOAudit: {
            showmsg: "待PMO审核",
            value: 10,
        },
        WaitFiAudit: {
            showmsg: "待财务审核",
            value: 20,
        },
        Finish: {
            showmsg: "已完成",
            value: 30,
        }
    },
    AccountingState: {
        Return: {
            showmsg: "退回",
            value: -10,
        },
        New: {
            showmsg: "待提交",
            value: 0,
        },
        CreateReport: {
            showmsg: "待提交报告",
            value: 10,
        },
        WaitPMOAudit: {
            showmsg: "待PMO审核",
            value: 20,
        },
        WaitFiAudit: {
            showmsg: "待财务审核",
            value: 30,
        },
        Finish: {
            showmsg: "已完成",
            value: 40,
        }
    },
    ColumnApplyType: {
        General: {
            showmsg: "通用",
            value: 0,
        },
        Export: {
            showmsg: "导出使用",
            value: 10,
        },
        Import: {
            showmsg: "导入使用",
            value: 20,
        }
    },
    ColumnImposeType: {
        Require: {
            showmsg: "必填",
            value: 0,
        },
        Unrequire: {
            showmsg: "不必填",
            value: 10,
        },
    },
    WaitAffairType: {
        BusinessCheck: {
            showmsg: "商机审核",
            value: '0102020109',
        },

        BusinessStartCheck: {
            showmsg: "商机启动审核",
            value: '0103010109',
        },
        BusinessStopCheck: {
            showmsg: "商机暂停审核",
            value: '0103010119',
        },
        BjCheck: {
            showmsg: "报价审核",
            value: '0103030109',
        },
        ContractRegister: {
            showmsg: "合同登记",
            value: '0103040102',
        },

        ContractCheck: {
            showmsg: "合同审核",
            value: '0103040109',
        },
        ProjectHandOverCheck: {
            showmsg: "项目移交审核",
            value: '0104010111',
        },
        ProjectReturnHandOverCheck: {
            showmsg: "退回项目移交审核",
            value: '_0104010111',
        },
        ProjectReceiveCheck: {
            showmsg: "项目接收审核",
            value: '0104010112',
        },
        ProjectPlanCheck: {
            showmsg: "项目计划审核",
            value: '0104020109',
        },
        CompletedApply: {
            showmsg: "竣工验收申请",
            value: '0104040102',
        },
        CompletedPMOCheck: {
            showmsg: "竣工验收PMO审核",
            value: '0104040109',
        },
        CompletedFinancialCheck: {
            showmsg: "竣工验收财务审核",
            value: '0104040119',
        },
        ProjectshutPMOCheck: {
            showmsg: "项目关闭PMO审核",
            value: '0104050109',
        },
        ProjectshutFinancialCheck: {
            showmsg: "项目关闭财务审核",
            value: '0104050119',
        },
        ProblemCheck: {
            showmsg: "问题管理反馈审核",
            value: '0105010109',
        },
        BudgetPMOCheck: {
            showmsg: "预算PMO审核",
            value: '0110010109',
        },
        BudgetFinancialCheck: {
            showmsg: "预算财务审核",
            value: '0110010119',
        },

        FinalAccountPMOCheck: {
            showmsg: "决算PMO审核",
            value: '0110030109',
        },
        FinalAccountFinancialCheck: {
            showmsg: "决算财务审核",
            value: '0110030119',
        },
        FinalAccountReportCheck: {
            showmsg: "决算报告PMO审核",
            value: '0110030129',
        },
        FinalAccountReportFinancialCheck: {
            showmsg: "决算报告财务审核",
            value: '0110030139',
        },

    },

    Problemstate: {
        WAITCOMMIT: {
            showmsg: "待提交",
            value: 10,
        },
        WAITFEEDBACK: {
            showmsg: "待反馈",
            value: 20,
        },
        // WAITFEEDBACKEDIT: {
        //     showmsg: "待反馈编辑",
        //     value: 21,
        // },
        WAITCHECK: {
            showmsg: "待审核",
            value: 30,
        },
        FINISH: {
            showmsg: "完成",
            value: 40,
        },
        BACK: {
            showmsg: "退回",
            value: -20,
        },
    },
    ProblemType: {
        Safe: {
            showmsg: "安全",
            value: '安全',
        },
        Quality: {
            showmsg: "质量",
            value: '质量',
        },
        Progress: {
            showmsg: "进度",
            value: '进度',
        },
        Other: {
            showmsg: "其他",
            value: '其他',
        },
    },
    CompletedState: {
        liveaccept: {
            operation: "edit",
            showmsg: "待现场验收",
            value: 10
        },
        apply: {
            operation: "apply",
            showmsg: "待提交申请",
            value: 20

        },
        check: {
            operation: "check",
            showmsg: "待审核(PMO)",
            value: 30
        },
        // finished:{
        //     operation:"finished",
        //     showmsg: "验收通过",
        //     value: 40
        // },
        // unfinished: {
        //     operation:"unfinished",
        //     showmsg: "验收不通过",
        //     value: 50
        // },
        fincheck: {
            operation: "fincheck",
            showmsg: "待审核(财务)",
            value: 60
        },
        back: {
            operation: "back",
            showmsg: "退回",
            value: -20
        },
    },
    ProjectshutState: {
        apply: {
            operation: "apply",
            showmsg: "提交申请",
            value: 10
        },
        check: {
            operation: "check",
            showmsg: "PMO审核",
            value: 20
        },
        back: {
            operation: "back",
            showmsg: "退回",
            value: -20
        },
        fincheck: {
            operation: "fincheck",
            showmsg: "财务审核",
            value: 60
        }
    },

    OrgexpandType: {
        DepartmentTargetMoney: {
            showmsg: "事业部销售目标",
            value: 10,
        },
        SalemanTargetMoney: {
            showmsg: "销售经理销售目标",
            value: 20,
        }
    },

    QuinzeTaskStatus: {
        InProgress: {
            showmsg: "进行中",
            // showmsg: "In progress",
            value: 1,
        },
        Complete: {
            showmsg: "已完成",
            // showmsg: "Complete",
            value: 2,
        },
        Pending: {
            showmsg: "暂停",
            // showmsg: "Pending",
            value: 3,
        }
    },
    PmquinzeState: {
        Back: {
            showmsg: "退回",
            value: -2,
        },
        WaitCom: {
            showmsg: "待提交",
            value: 1,
        },
        WaitAudit: {
            showmsg: "待审核",
            value: 2,
        },
        Finish: {
            showmsg: "已完成",
            value: 3
        }
    },
    ManagerCheckTargetType: {
        target1: {
            showmsg: "项目按进度要求合同里程碑节点完成，无外部进度投诉、罚款",
            value: 1,
            totalscore: 10,
            fromtype: 1
        },
        target2: {
            showmsg: "项目按进度要求完成初验（安装完成）、终验",
            value: 2,
            totalscore: 10,
            fromtype: 1
        },
        target3: {
            showmsg: "项目按质量要求完成，无外部质量投诉、罚款",
            value: 3,
            totalscore: 20,
            fromtype: 1
        },
        target4: {
            showmsg: "项目按安全要求完成，无内外部安全投诉、罚款",
            value: 4,
            totalscore: 20,
            fromtype: 1
        },
        target5: {
            showmsg: "项目按预算成本要求完成-项目总预算",
            value: 5,
            totalscore: 6,
            fromtype: 1
        },
        target6: {
            showmsg: "项目按预算成本要求完成-阿米巴PMO部分",
            value: 6,
            totalscore: 8,
            fromtype: 1
        },
        target7: {
            showmsg: "现场5S管理",
            value: 7,
            totalscore: 6,
            fromtype: 1
        },
        target8: {
            showmsg: "周报、双周文件提交按时、合理、准确",
            value: 8,
            totalscore: 5,
            fromtype: 1
        },
        target9: {
            showmsg: "预算及决算文件提交按时、合理、准确",
            value: 9,
            totalscore: 5,
            fromtype: 1
        },
        target10: {
            showmsg: "项目应收账款及时，国内项目偏差≤10天，国外项目偏差≤15天",
            value: 10,
            totalscore: 10,
            fromtype: 1
        },
        target11: {
            showmsg: "现场安装期间的进度要求按合同里程碑节点完成，无外部进度投诉、罚款（*备注1）",
            value: 11,
            totalscore: 15,
            fromtype: 2
        },
        target12: {
            showmsg: "项目现场安装按质量要求完成，无外部质量投诉、罚款（*备注1）（*备注2）",
            value: 12,
            totalscore: 20,
            fromtype: 2
        },
        target13: {
            showmsg: "项目现场安装按安全要求完成，无内外部安全投诉、罚款（*备注1）",
            value: 13,
            totalscore: 20,
            fromtype: 2
        },
        target14: {
            showmsg: "项目按预算成本要求完成 - 控制分包费用及现场增补费用",
            value: 14,
            totalscore: 10,
            fromtype: 2
        },
        target15: {
            showmsg: "机具耗材清单，合理无缺件，按金额计算控制在±5%以内",
            value: 15,
            totalscore: 15,
            fromtype: 2
        },
        target16: {
            showmsg: "现场5S管理",
            value: 16,
            totalscore: 20,
            fromtype: 2
        },
    },

    ManagerType: {
        ProjectManager: {
            showmsg: "项目经理",
            value: 1,
        },
        SiteManager: {
            showmsg: "现场经理",
            value: 2,
        },
    },


    /**
     * 使用分类
     */
    OtherItemUsetype: {
        Radio: {
            showmsg: "单选框",
            value: 1,
        },
        Input: {
            showmsg: "输入框",
            value: 2,
        },
        RadioAndInput: {
            showmsg: "单选框加输入框",
            value: 3,
        },
        Select: {
            showmsg: "下拉选择",
            value: 4,
        },
        RadioAndDatePicker: {
            showmsg: "单选框加日期选择框",
            value: 5,
        },
        Inputs: {
            showmsg: "多个输入框",
            value: 6,
        },
        TextArea: {
            showmsg: "文本域",
            value: 7,
        },
        AutoComplete: {
            showmsg: "输入和下拉选择框",
            value: 8,
        },
        AutoCompleteAndInput: {
            showmsg: "输入和下拉选择框加输入框",
            value: 9,
        },
    },

    /**
     * 变更状态
     */
    ChangeStatus: {
        Fail: {
            showmsg: "不通过",
            value: -10,
        },
        WaitAudit: {
            showmsg: "待审核",
            value: 0,
        },
        Pass: {
            showmsg: "通过",
            value: 10,
        },
        Create: {
            showmsg: "创建中",
            value: -5,
        },
    },

    /**
     * 变更状态
     */
    ScopechangeStatus: {
        Return: {
            showmsg: "退回",
            value: -10,
        },
        New: {
            showmsg: "待提交",
            value: 0,
        },
        WaitAudit: {
            showmsg: "待审核",
            value: 10,
        },
        Finish: {
            showmsg: "已完成",
            value: 20,
        },
    }
}


export default axios;
