import React, {Component, lazy, Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Spin} from 'antd';

// 首页
const AdminHome = lazy(() => import('../components/admin/home/AdminHome'));

// 新闻
const AdminNews = lazy(() => import('../components/admin/news/AdminNews'));
const AdminNewsView = lazy(() => import('../components/admin/news/AdminNewsView'));
const AdminNewsEdit = lazy(() => import('../components/admin/news/AdminNewsEdit'));

// 首页
const rootAdminHome = ({match}) => {
    return (
        <Suspense fallback={<div className="maintip" ><Spin tip="正在加载..." /></div >} >
            <Switch >
                <Route path={"/"} component={AdminHome} />
            </Switch >
        </Suspense >
    )
};

// 新闻
const rootAdminNews = ({match}) => {
    return (
        <Suspense fallback={<div className="maintip" ><Spin tip="正在加载..." /></div >} >
            <Switch >
                <Route exact path={`${match.path}`} component={AdminNews} />
                <Route path={`${match.path}/NewsDetail`} component={AdminNewsView} />
                <Route path={`${match.path}/NewsEdit`} component={AdminNewsEdit} />
            </Switch >
        </Suspense >
    )
};


export default class AdminRoute extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Suspense fallback={<div className="maintip" ><Spin tip="正在加载..." /></div >} >
                <Switch >
                    <Route exact path="/AdminSystem" component={rootAdminHome} />
                    <Route path="/AdminSystem/Home" component={rootAdminHome} />
                    <Route path="/AdminSystem/News" component={rootAdminNews} />
                    <Route render={() => <Redirect to="/404" />} />
                </Switch >
            </Suspense >
        )
    }
}
