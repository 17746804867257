import React from 'react';
import {useHistory} from "react-router-dom";

import adminLogo from "../../../style/imgs/admin/galaxy_logo.png"
import {Button, Form, Input} from "antd";

import {UserOutlined, LockOutlined} from "@ant-design/icons"

const AdminLogin = () => {

    const [loginForm] = Form.useForm();
    const history = useHistory();


    /**
     * 登录
     */
    const submitLogin = () => {
        loginForm.validateFields().then(r => {
            history.push({
                pathname: "/AdminSystem/Home"
            })
        })
    }

    return (
        <div className={"admin-login"} >
            <div className={"admin-login-body"} >
                <div >
                    <img className={"admin-logo"} src={adminLogo} alt="logo" />
                    <div className={"admin-title mt-s"} >星舰管理后台登录</div >
                </div >
                <div className={"mt-l"} >
                    <Form form={loginForm} >
                        <Form.Item name={"username"} rules={[{required: true, message: '用户名不能为空'}]} >
                            <Input size={"large"} placeholder={"用户名"} prefix={<UserOutlined />} />
                        </Form.Item >
                        <Form.Item name={"password"} rules={[{required: true, message: '密码不能为空'}]} >
                            <Input size={"large"} placeholder={"密码"} prefix={<LockOutlined />} />
                        </Form.Item >
                    </Form >
                    <div className={"mt-m"} >
                        <Button type={"primary"} size={"large"} block onClick={submitLogin} >登录</Button >
                    </div >
                </div >


            </div >
        </div >
    );
}

AdminLogin.propTypes = {};

export default AdminLogin;