import React from 'react';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import NotFound from './components/pages/NotFound';

import App from './App';
import Admin from "./Admin";
import AdminLogin from "./components/admin/login/AdminLogin";

export default () => (
    <Router >
        <Switch >
            <Route path="/AdminLogin" component={AdminLogin} />
            <Route path="/AdminSystem" component={Admin} />
            <Route path="/" component={App} />
            <Route path="/404" component={NotFound} />
            <Route component={NotFound} />
        </Switch >
    </Router >
)
