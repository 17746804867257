import React, {Component, lazy, Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Spin} from 'antd';

// 首页
const Home = lazy(() => import('../components/home/Home.jsx'));
const ProductDetail = lazy(() => import('../components/product_detail/ProductDetail.jsx'));
const ProductPrice = lazy(() => import('../components/product_price/ProductPrice.jsx'));
const Solution = lazy(() => import('../components/solution/Solution.jsx'));
const News = lazy(() => import('../components/news/News.jsx'));
const NewsDetail = lazy(() => import('../components/news/NewsDetail.jsx'));
const AboutUs = lazy(() => import('../components/about_us/AboutUs.jsx'));

//公共部分

// 首页
const rootHome = ({match}) => {
    return (
        <Suspense fallback={<div className="maintip" ><Spin tip="正在加载..." /></div >} >
            <Switch >
                <Route path={"/"} component={Home} />
            </Switch >
        </Suspense >
    )
};

// 产品介绍
const rootProductDetail = ({match}) => {
    return (
        <Suspense fallback={<div className="maintip" ><Spin tip="正在加载..." /></div >} >
            <Switch >
                <Route path={`${match.path}`} component={ProductDetail} />
            </Switch >
        </Suspense >
    )
};

// 产品价格
const rootProductPrice = ({match}) => {
    return (
        <Suspense fallback={<div className="maintip" ><Spin tip="正在加载..." /></div >} >
            <Switch >
                <Route path={`${match.path}`} component={ProductPrice} />
            </Switch >
        </Suspense >
    )
};

// 解决方案
const rootSolution = ({match}) => {
    return (
        <Suspense fallback={<div className="maintip" ><Spin tip="正在加载..." /></div >} >
            <Switch >
                <Route path={`${match.path}`} component={Solution} />
            </Switch >
        </Suspense >
    )
};

// 新闻
const rootNews = ({match}) => {
    return (
        <Suspense fallback={<div className="maintip" ><Spin tip="正在加载..." /></div >} >
            <Switch >
                <Route exact path={`${match.path}`} component={News} />
                <Route path={`${match.path}/*`} component={NewsDetail} />
            </Switch >
        </Suspense >
    )
};

// 关于
const rootAboutUs = ({match}) => {
    return (
        <Suspense fallback={<div className="maintip" ><Spin tip="正在加载..." /></div >} >
            <Switch >
                <Route path={`${match.path}`} component={AboutUs} />
            </Switch >
        </Suspense >
    )
};


export default class CRouter extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Suspense fallback={<div className="maintip" ><Spin tip="正在加载..." /></div >} >
                <Switch >
                    <Route exact path="/" component={rootHome} />
                    <Route path="/ProductDetail" component={rootProductDetail} />
                    <Route path="/ProductPrice" component={rootProductPrice} />
                    <Route path="/News" component={rootNews} />
                    <Route path="/Solution" component={rootSolution} />
                    <Route path="/AboutUs" component={rootAboutUs} />
                    <Route render={() => <Redirect to="/404" />} />
                </Switch >
            </Suspense >
        )
    }
}
