import React from 'react';
import {Modal, Select} from 'antd';

const {Option} = Select;
export const Tool = {};
let alertText = document.createElement('div');
alertText.setAttribute('id', 'alertText');

let alertDom = document.createElement('div');
alertDom.setAttribute('id', 'alertTip');
alertDom.appendChild(alertText);

document.body.appendChild(alertDom);
let timer = null;
Tool.alerterror = (err) => {
    const modal = Modal.error({
        title: '提示',
        content: err != null ? err.toString() : "操作失败！",
        width: 320,
    });
    setTimeout(() => modal.destroy(), 8000);
}
/**
 * 弹窗提示，不自动关闭弹窗
 * @param msg
 */
Tool.alerterroronok = (msg) => {
    Modal.error({
        title: '提示',
        content: msg.toString(),
        width: 320,
        onOk() {
        },
    });
}
Tool.showinfo = (msg, title) => {
    if (title == null || title.length == 0) {
        const modal = Modal.info({
            title: '提示',
            content: msg.toString(),
            width: 320,
        });
        setTimeout(() => modal.destroy(), 1000);
    } else {
        const modal = Modal.info({
            title: title,
            content: msg.toString(),
            width: 320,
        });
        setTimeout(() => modal.destroy(), 1000);
    }
}

/**
 * 弹窗提示，不自动关闭弹窗
 * @param msg
 */
Tool.showinfoonok = (msg) => {
    Modal.info({
        title: '提示',
        content: msg.toString(),
        width: 320,
        onOk() {
        },
    });
}
/**
 * 弹窗提示，不自动关闭弹窗,并刷新页面
 * @param msg
 */
Tool.showinfoonokandRefresh = (msg) => {
    Modal.info({
        title: '提示',
        content: msg.toString(),
        width: 320,
        onOk() {
            window.location.reload();
        },
    });
}
Tool.showinfowithtimeout = (msg, timeout) => {
    const modal = Modal.info({
        title: '提示',
        content: msg,
        width: 320,
        style: {left: "10%"}
    });
    setTimeout(() => modal.destroy(), timeout);
}

Tool.getStyle = (obj, attr) => {
    if (obj.currentStyle) {
        return obj.currentStyle[attr];
    } else {
        return document.defaultView.getComputedStyle(obj, null)[attr];
    }
}
/**
 * 枚举类构建为 Option列表
 * @param enumdata  枚举类对象/数组
 * @param needpleaseselect  是否有全选
 * @param onlyforselect 指定枚举类中需要的枚举值
 * @param unselectarr 指定不需要的枚举值数组
 * @returns {[]}
 */
Tool.createSelectOption = (enumdata, needpleaseselect, onlyforselect, unselectarr) => {
    let options = [];
    if (needpleaseselect) {
        let defaultoption = Tool.createStringAllSelect();
        options.push(defaultoption);
    }
    if (enumdata != null) {
        for (let i in enumdata) {
            let currentitem = enumdata[i];
            if (onlyforselect != null && onlyforselect.length > 0) {
                if (Tool.isContailSelect(currentitem, onlyforselect)) {
                    let foroption = <Option key={currentitem.value}
                                            value={currentitem.value}>{currentitem.showmsg}</Option>;
                    options.push(foroption);
                }
            } else if ((onlyforselect == null || onlyforselect.length <= 0) && unselectarr != null && unselectarr.length > 0) {
                if (Tool.notContailSelect(currentitem, unselectarr)) {
                    let foroption = <Option key={currentitem.value}
                                            value={currentitem.value}>{currentitem.showmsg}</Option>;
                    options.push(foroption);
                }
            } else {
                let foroption = <Option key={currentitem.value}
                                        value={currentitem.value}>{currentitem.showmsg}</Option>;
                options.push(foroption);
            }

        }
    } else {
        let arr = [];
        for (let i in enumdata) {
            let o = {};
            o.showmsg = enumdata[i].showmsg;
            o.value = enumdata[i].value;
            arr.push(o);
        }
        if (arr != null && arr.length > 0) {
            if (onlyforselect != null && onlyforselect.length > 0) {
                for (let i = 0; i < arr.length; i++) {
                    let currentitem = arr[i];
                    if (Tool.isContailSelect(currentitem, onlyforselect)) {
                        let foroption = <Option key={currentitem.value}
                                                value={currentitem.value}>{currentitem.showmsg}</Option>;
                        options.push(foroption);
                    }
                }
            } else {
                for (let i = 0; i < arr.length; i++) {
                    options.push(<Option key={arr[i].value.toString()} value={arr[i].value}>{arr[i].showmsg}</Option>)
                }
            }
        }
    }
    return options;
}
Tool.isContailSelect = (currentitem, onlyforselect) => {
    let isexist = false;
    if (onlyforselect != null && onlyforselect.length > 0) {
        for (var i = 0; i < onlyforselect.length; i++) {
            if (onlyforselect[i] == currentitem.value) {
                isexist = true;
                break;
            }
        }
    } else {
        isexist = true;
    }
    return isexist;
}
Tool.notContailSelect = (currentitem, unselectarr) => {
    let isexist = false;
    if (unselectarr != null && unselectarr.length > 0) {
        for (let value of unselectarr) {
            if (value != currentitem.value) {
                isexist = true;
                break;
            }
        }
    } else {
        isexist = true;
    }
    return isexist;
}
Tool.createAllSelect = () => {
    return <Option key={99} value={99}>不限</Option>;
}
Tool.createStringAllSelect = () => {
    return <Option key="" value="">请选择</Option>;
}
Tool.createCommonYesOrNo = (needpleaseselect) => {
    let options = [];
    if (needpleaseselect) {
        let defaultoption = <Option key={null} value={null}>不限</Option>;
        options.push(defaultoption);
    }
    options.push(<Option value={false}>否</Option>);
    options.push(<Option value={true}>是</Option>)
    return options;
}

Tool.initTabActiveKey = (obj) => {
    let key;
    key = localStorage.getItem('tabActiveKey') || 'billboard';
    obj.setState({
        tabActiveKey: key
    })
};
/**
 * 移除数组最后的对像(一般用来删除表格字段最后的操作栏)
 * @param arr[Array]
 */
Tool.removeLastObj = (arr) => {
    arr = arr.slice(0, arr.length - 1);
    return arr
};

/**
 * 子表格有数据的时候才显示展开图标
 * @param props{T}
 * @param field{string}
 * @returns {*}
 */
Tool.autoExpandIcon = (props, field) => {
    if (props.record[field]) {
        if (props.record[field].length > 0) {
            if (props.expanded) {
                return <div role="button" tabIndex="0" className="ant-table-row-expand-icon ant-table-row-expanded"
                            aria-label="展开行" onClick={e => {
                    props.onExpand(props.record, e);
                }} />
            } else {
                return <div role="button" tabIndex="0" className="ant-table-row-expand-icon ant-table-row-collapsed"
                            aria-label="展开行" onClick={e => {
                    props.onExpand(props.record, e);
                }} />
            }
        }
    } else {
        return <span style={{marginRight: 8}} />
    }
};

Tool.autoExpandIconValue = (props, field, needvalue) => {
    if (props.record[field] != null) {
        if (props.record[field] != null && props.record[field] == needvalue) {
            if (props.expanded) {
                return <div role="button" tabIndex="0" className="ant-table-row-expand-icon ant-table-row-expanded"
                            aria-label="展开行" onClick={e => {
                    props.onExpand(props.record, e);
                }} />
            } else {
                return <div role="button" tabIndex="0" className="ant-table-row-expand-icon ant-table-row-collapsed"
                            aria-label="展开行" onClick={e => {
                    props.onExpand(props.record, e);
                }} />
            }
        }
    } else {
        return <span style={{marginRight: 8}} />
    }
};

/**
 * 根据枚举类的value，获取枚举类的showmsg
 * @param enumdata
 * @param onlyforselect
 * @returns {[]}
 */
Tool.queryEnumShowname = (enumdata, onlyforselect) => {
    let showname;
    if (onlyforselect != null) {
        for (let i in enumdata) {
            if (enumdata[i].value == onlyforselect) {
                showname = enumdata[i].showmsg;
                break;
            }
        }
    }
    return showname;
};

/**
 * 合并两个数组并去重
 * @param arr1
 * @param arr2
 * @returns {[]}
 * @constructor
 */
Tool.MergeArray = (arr1, arr2) => {
    let _arr = [];
    for (let i = 0; i < arr1.length; i++) {
        _arr.push(arr1[i]);
    }
    for (let i = 0; i < arr2.length; i++) {
        let flag = true;
        for (let j = 0; j < arr1.length; j++) {
            if (arr2[i] === arr1[j]) {
                flag = false;
                break;
            }
        }
        if (flag) {
            _arr.push(arr2[i]);
        }
    }
    return _arr;
}

/**
 * 数据根据id去重
 * @param array [Array] 数据
 * @param id [String] 主键
 * @returns {Array}
 */
Tool.uniqueArray = function (array, id) {
    for (var i = 0; i < array.length; i++) {
        for (var j = i + 1; j < array.length; j++) {
            if (array[i][id] === array[j][id]) {
                array.splice(j, 1);
                j--;
            }
        }
    }
    return array;
};

/**
 * 深拷贝数据
 * @param obj
 */
Tool.clone = (obj) => {
    let newObj;
    if (Array.isArray(obj)) { // 判断复制的目标是不是数组
        newObj = [];
    } else if (typeof obj === 'object') {
        newObj = {};
    } else {
        newObj = obj;
    }
    if (typeof obj === 'object') {
        for (let item in obj) {
            if(obj.hasOwnProperty(item)) {
                if(obj[item] && typeof obj[item] === 'object') {
                    newObj[item] = Tool.clone(obj[item])
                } else {
                    newObj[item] = obj[item]
                }
            }
        }
    }
    return newObj;
}
