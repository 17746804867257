/**
 * Created by marshal on 2017/4/16.
 */

import axios, {Common} from './index';
export const Login = {
    servicename:"/api-uaa",
    dologin: function (loginobj) {
        return axios({
            url:this.servicename+'/baseuser/ac_login',
            method: "GET",
            params: {
                loginname: loginobj.userName,
                loginpwd: loginobj.password,
                checkcode: loginobj.checkcode, // 验证码
                ssotoken: loginobj.ssotoken, // 免密登录令牌
                utoken: loginobj.utoken, // 用户token
                loginuuid: loginobj.loginuuid,
            },
        });
    },
//退出登录
    dologout: function (loginobj) {
        return axios({
            url: this.servicename+'/baseuser/ac_logout',
            method: "GET",
        });
    }
}
export  const  dogetUserinfo=()=> {
    axios({
        url: '/BaseUser/dogetUserinfo',
        method: "GET",
    }).then(res=>{
        localStorage.setItem('user', res.data);
        return res.data;
    })

}
