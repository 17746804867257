import React from 'react';
import {Popover} from 'antd';
import {CommentOutlined, WhatsAppOutlined, PhoneFilled} from "@ant-design/icons";
import qrCodeImg from "../../style/imgs/home/u106.png"


const BackTopPreItem = () => {
    return (
        <div className={"back-top-pre-item"} >
            <Popover placement={"left"} trigger={"hover"} content={
                <div ><PhoneFilled className={"mr-s"} />020-89858579</div >
            } >
                <div className={"back-top-grid"} >
                    <WhatsAppOutlined />
                </div >
            </Popover >
            <Popover placement={"left"} trigger={"hover"} content={
                <div >
                    <img src={qrCodeImg} alt="qrCode" style={{height: 'auto', width: 120}} />
                </div >
            } >
                <div className={"back-top-grid"} >
                    <CommentOutlined />
                </div >
            </Popover >
        </div >
    );
}

BackTopPreItem.propTypes =
    {};

export default BackTopPreItem;