import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Breadcrumb} from "antd";
import {HomeOutlined} from "@ant-design/icons";

const AdminBreadCrumb = ({data}) => {
    const history = useHistory();
    const [breadData, setBreadData] = useState(data)
    useEffect(() => {
        let _breadData = [...data];
        if (history.location.query) {
            const breadcrumbName = history.location.query.breadcrumbName;
            if (breadcrumbName) {
                _breadData.push({label: breadcrumbName})
            }
        }
        setBreadData(_breadData)
    }, [history.location, data])
    return (
        <div >
            <Breadcrumb >
                <Breadcrumb.Item href="/AdminSystem/Home" >
                    <HomeOutlined />
                </Breadcrumb.Item >
                {
                    breadData.map((item, index) => <Breadcrumb.Item key={`bread-item-${index}`}
                                                                    href={item.href || "#"} >
                        {item.label}
                    </Breadcrumb.Item >)
                }
            </Breadcrumb >
        </div >
    );
}

AdminBreadCrumb.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object)
};

export default AdminBreadCrumb;